import bootstrap3 from '../components/FormioBootstrap3Template'
import { Formio } from 'formiojs'

import { ApplicationController } from './application_controller.js'

export default class extends ApplicationController {
  static targets = ['form']
  static values = {
    redirectUrl: String,
    formUrl: String,
    localStorage: String,
    template: Object,
    initial: Object,
    templateVersion: String,
    relationships: Array,
    editMode: Boolean,
  }
  connect() {
    const controller = this
    console.log('rx', this.relationshipsValue)
    Formio.use(bootstrap3)
    Formio.Templates.framework = 'bootstrap3'
    Formio.icons = 'fontawesome';
    if (!this.hasFormTarget) {
      console.log('formTarget not found')
      return
    }
    this.formChanged = false
    this.formTarget.dataset.changed = false
    Formio.createForm(this.formTarget, this.templateValue, {
      hooks: {
        beforeSubmit: (submission, next) => {
          console.log('beforeSubmit', submission)
          next()
        }
      }
    }).then((form) => {
      form.nosubmit = true
      form.submission = { data: controller.initialValue }
      try {
        const draft = sessionStorage.getItem(controller.localStorageValue)
        if (draft) {
          console.log('restoring draft')
          form.submission = { data: JSON.parse(draft) }
        }
      } catch (e) {
        console.log('error restoring draft', e)
      }
      // send data to local storage every 60 seconds
      setInterval(() => {
        try {
          sessionStorage.setItem(
            controller.localStorageValue,
            JSON.stringify(form.submission.data)
          )
          console.log('to local storage:', JSON.stringify(form.submission.data))
        } catch (e) {
          console.log('error saving to sessionStorage', e)
        }
      }, 60000)
      form.on('submit', function (submission) {
        console.log('original submission', submission)
        submission.components = form.component.components
        const data = {
          form_submission: {
            json_data: JSON.stringify(submission),
            template_version: controller.templateVersionValue,
            relationships_attributes: controller.relationshipsValue.map(
              (gid) => ({ entity_gid: gid })
            )
          }
        }
        console.log('submit modified submission', submission)
        console.log('submitting', data)
        return Formio.fetch(controller.formUrlValue, {
          method: controller.editModeValue ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': controller.csrfToken
          },
          credentials: 'same-origin',
          body: JSON.stringify(data)
        })
          .then((response) => {
            if (response.ok) {
              controller.formChanged = false
              sessionStorage.removeItem(controller.localStorageValue)
              form.emit('submitDone', submission)
            }
            else {
              response.json()
                .then(error => {
                  console.log(error);
                  form.emit('submitError', error._embedded.errors.map(e => e.message).join('<br/>'));
                })
                .catch(e => {
                  form.emit('submitError', e);
                })
            }
          })
          .catch((error) => {
            console.log('error', error)
            form.emit('submitError', error)
          })
      })
      form.on('change', (changed) => {
        window.formio = form
        controller.formChanged = true
      })
      form.on('submitDone', (submission) => {
        sessionStorage.removeItem(controller.localStorageValue)
        window.location = controller.redirectUrlValue
      })
      form.on('submitError', (submission) => {
        this.submitted = false
        // alert('An error submitting report...')
        console.log(submission)
      })
    })
    this.formTarget.addEventListener('submit', (event) => {
      controller.formChanged = false
    })
    window.addEventListener('beforeunload', function (e) {
      console.log('beforeunload listener fires')
      if (controller.formChanged) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    })
  }
}
