import { ApplicationController } from './application_controller.js'
import Cookies from 'js-cookie'
export default class extends ApplicationController {
  static outlets = ['assign']
  static targets = ['sortButton', 'conflictButton','saveAllButton', 'openAllButton']

  async saveAll(){
    try {
      this.saveAllButtonTargets.forEach((target) => target.disabled = true)
      const unsavedAssignments = this.assignOutlets.filter((outlet) => outlet.hasUnsavedChanges)
      let queue = []
      while (unsavedAssignments.length > 0){
        queue = []
        queue.push(this.pause(2000))
        for (let i = 0; i < 5; i++) {
          const assignment = unsavedAssignments.shift()
          if (assignment){
            queue.push(assignment.save())
          }
        }
        await Promise.all(queue)
      }
      return Promise.resolve(true)
    } finally {
      this.saveAllButtonTargets.forEach((target) => target.disabled = false)
    }
  }

  pause(ms){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log("pause resolved")
        resolve(true);
      }, ms);
    })
  }

  async openAll(){
    try {
      this.openAllButtonTargets.forEach((target) => target.disabled = true)
      const unopenedAssignments = this.assignOutlets.filter((outlet) => !outlet.hasUnsavedChanges)
      let queue = []
      while (unopenedAssignments.length > 0){
        queue = []
        queue.push(this.pause(2000))
        for (let i = 0; i < 5; i++) {
          const assignment = unopenedAssignments.shift()
          if (assignment){
            queue.push(assignment.populate())
          }
        }
        await Promise.all(queue)
      }
      return Promise.resolve(true)
    } finally {
      this.openAllButtonTargets.forEach((target) => target.disabled = false)
    }
  }

  resetConflict(event){
    const { conflict } = event.params
    console.log(`reset conflict: ${conflict}`)
    Cookies.set('assign-conflict', conflict)
    this.assignOutlets.forEach((assign) => {
      assign.changeConflict(conflict)
    })
    this.conflictButtonTargets.forEach((button) => {
      if (button.dataset.assignFooterConflictParam == conflict) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })

  }

  resetSort(event) {
    const { sort } = event.params
    console.log(`reset sort: ${sort}`)
    Cookies.set('assign-sort', sort)
    this.assignOutlets.forEach((assign) => {
      assign.changeSort(sort)
    })
    this.sortButtonTargets.forEach((button) => {
      if (button.dataset.assignFooterSortParam == sort) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })
  }

  connect() {
    const sort = Cookies.get('assign-sort')
    const sortValue = sort || 'name'
    this.sortButtonTargets.forEach((button) => {
      if (button.dataset.assignFooterSortParam == sortValue) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })

    const conflict = Cookies.get('assign-conflict')
    const conflictValue = conflict || 'no'
    this.conflictButtonTargets.forEach((button) => {
      if (button.dataset.assignFooterConflictParam == conflictValue) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })
  }
}
