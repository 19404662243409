import { ApplicationController } from './application_controller.js'

export default class extends ApplicationController {
  static targets = ['format', 'report']
  connect() {}
  change() {
    const report = this.reportTarget.value
    const reportOption = this.reportTarget.querySelector(
      `option[value="${report}"]`
    )
    const formatOptions = this.formatTarget.querySelectorAll('option')
    const selectedFormat = this.formatTarget.value
    formatOptions.forEach((option) => {
      const format = option.value
      if (reportOption.dataset[format]) {
        option.disabled = reportOption.dataset[format] === 'false'
      } else {
        option.disabled = false
      }
    })
    const selectedFormatOption = Array.from(
      this.formatTarget.querySelectorAll('option')
    ).find((option) => option.value === selectedFormat)

    if (selectedFormatOption.disabled) {
      console.log('selectedFormatOption is disabled')
      const firstEnabledOption = Array.from(
        this.formatTarget.querySelectorAll('option')
      ).find((option) => !option.disabled)
      this.formatTarget.value = firstEnabledOption.value
    }
  }
}
