import { ApplicationController } from './application_controller.js'
// used when accepts_nested_attributes_for is used in a form.
// set data-controller="nested-attributes-row" on the form tag,
// add a button with data-action="nested-attributes-row#add" to add a row
// set data-target="nested-attributes-row.row" on each row to be added/removed
// see views/task_sheet/template_assignments/new.html.erb for an example
export default class extends ApplicationController {
  static targets = ['row']
  static values = {
    clearOnDuplicate: Boolean
  }
  static outlets = ['multiselect']
  connect() {
    console.log('na-row')
    console.log(this.rowTargets)
    console.log('cod: ', this.clearOnDuplicateValue)
  }

  get visibleRows() {
    return this.rowTargets.filter((row) => row.offsetParent)
  }

  add(event) {
    const controller = this
    const randomID = Math.round(Math.random() * 1000000000).toString()
    event.preventDefault()
    console.log(event)
    console.log(this.rowTargets)
    this.multiselectOutlets.forEach((controller) => controller.revert())
    const lastRow = this.visibleRows[this.visibleRows.length - 1]
    const rowContainer = lastRow.parentNode
    const newRow = lastRow.cloneNode(true)
    console.log(newRow)

    newRow.querySelectorAll('select').forEach((item, i) => {
      if (!item.multiple) {
        item.selectedIndex = 0
      } else {
        const selected = item.querySelectorAll('option:checked')
        Array.from(selected).forEach(el => el.checked = false);    
      }
      item.name = item.name.replace(/\]\[[0-9]+\]\[/, '][' + randomID + '][')
      item.id = item.id.replace(/[0-9]+/, randomID)
    })
    newRow.querySelectorAll('input').forEach((item, i) => {
      item.name = item.name.replace(/\]\[[0-9]+\]\[/, '][' + randomID + '][')
      item.id = item.id.replace(/[0-9]+/, randomID)
      if (item.name.match(/\[id\]$/) || controller.clearOnDuplicateValue) {
        item.value = ''
      }
    })

    newRow
      .querySelectorAll('trix-toolbar')
      .forEach((element) => element.remove())
    newRow.querySelectorAll('trix-editor').forEach((trixEditor, i) => {
      // remove and replace copied trix editor with a new one.
      const { inputElement } = trixEditor
      const previousInput = trixEditor.innerHTML
      const hiddenFieldId = inputElement.id.replace(/[0-9]+/, randomID)
      const hiddenFieldName = inputElement.name.replace(/[0-9]+/, randomID)
      const newHiddenField = newRow.querySelectorAll(`#${hiddenFieldId}`)[0]

      console.log("previous hidden field: ", inputElement)
      console.log("new hidden field: ", newHiddenField)
      // const newInputElement = document.getElementById(id)
      const newTrix = document.createElement('trix-editor')
      window.newTrix = newTrix
      // window.txInputElement = inputElement
      // newTrix.input = id
      trixEditor.after(newTrix)
      trixEditor.remove()
      // trix auto-attaches a new hidden field, so we will set the name of
      // this field to the expected ID

      // newTrix.inputElement.name = hiddenFieldName
      // newTrix.inputElement.id = hiddenFieldId
      newHiddenField.remove()
      newTrix.inputElement.name = hiddenFieldName
      if (!controller.clearOnDuplicateValue) {
        newTrix.innerHTML = previousInput
      }
    })
    this.showElement(newRow)
    console.log('new ID is ' + randomID)
    console.log(newRow)
    rowContainer.appendChild(newRow)
    this.multiselectOutlets.forEach((controller) => controller.build())
  }

  remove(event) {
    event.preventDefault()
    var button = event.target
    console.log('click!')
    if (this.rowTargets.length > 0) {
      this.rowTargets.forEach((row, i) => {
        if (row !== button && row.contains(button)) {
          let tags = row.getElementsByTagName('input')
          console.log(tags)
          for (var i = 0; i < tags.length; i++) {
            let item = tags[i]
            if (item.name.match(/\[_destroy\]$/) && item.type == 'checkbox') {
              item.checked = true
              console.log('checking checkbox', item)
            }
          }
          console.log('found row!')
          this.hideElement(row)
        }
      })
    }
  }
}
