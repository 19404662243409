import { ApplicationController } from "./application_controller.js"
const COUNTRY_FROM_IP = document.querySelector("meta[name='country']").getAttribute('content') || 'US'
export default class extends ApplicationController {
  static targets = [ 'phone' ]
  connect(){
    console.log('connected to libphonenumber')
    var controller = this;
    import(/* webpackChunkName: "libphonenumber" */ 'libphonenumber-js' )
    .then(module => {
       controller.asYouType = module.AsYouType;
       controller.parsePhoneNumber = module.parsePhoneNumber;
       controller.isValidPhoneNumber = module.isValidPhoneNumber;
       console.log('libphonenumber lazy loaded')
       this.phoneTargets.forEach((phoneTarget, i) => {
         console.log('phoneTarget',phoneTarget, phoneTarget.textContent)
         if (phoneTarget.textContent){
           phoneTarget.textContent = this.formatPhoneNumber(phoneTarget.textContent)
         }
       });
    })
  }
  initialize() {
    this.phoneNumber = this.element.value
    console.log('initialize with', this.element.id)
  }

  formatPhoneNumber(value){
    try {
      let nonPhone = value.replace(/[^a-z]+/gi,'')
      if (nonPhone.length > 0){
        return value
      }
      let phoneNumber = this.parsePhoneNumber(value,COUNTRY_FROM_IP)
      if (phoneNumber.isValid()){
        console.log("valid:",phoneNumber)
        return phoneNumber.formatNational()
      } else {
        console.log("invalid:",phoneNumber)
        return value
      }
    } catch (e) {
      console.log("error parsing phone number: ",value, e)
      return value
    }
  }

  formatTypedNumber(value,wasValue){
    try {
      if (!value) {
        return value
      }
      let nonPhone = value.replace(/[^a-z]+/gi,'')
      if (nonPhone.length > 0){
        return value
      }
      let numbers = value.replace(/[^0-9]+/g,'')
      let wasNumbers = wasValue ? wasValue.replace(/[^0-9]+/g,'') : ''
      if (numbers.length - wasNumbers.length > 2){
        console.log('multi-number')
        return value;
      }
      console.log({numbers: numbers, wasNumbers: wasNumbers})
      if (numbers.indexOf(wasNumbers) == -1 && wasNumbers.indexOf(numbers) == -1 ){
        console.log('not a substring')
        return value;
      }

      if (numbers && wasNumbers && numbers.length == wasNumbers.length && value.length != wasValue.length){
        console.log("same number")
        return value
      }
      let parsed = new this.asYouType(COUNTRY_FROM_IP).input(value)
      console.log(`number ${value} => ${parsed} for ${COUNTRY_FROM_IP}`)
      return parsed
    } catch (e) {
      console.log("error on formatTypedNumber",e)
      return value
    }
  }

  changed(event){
    console.log("changed!",event)
    this.element.value = this.formatPhoneNumber(this.element.value)
    console.log("set new value to ",this.element.value)
    this.phoneNumber = this.element.value
  }

}
