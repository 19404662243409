import { ApplicationController } from './application_controller.js'

export default class extends ApplicationController {
  static outlets = ['assign']
  static targets = ['game', 'assignButton', 'select', 'copyButton', 'all']
  connect() {
    console.log('copy-assignments connected')
  }

  copyOne(event) {
    event.preventDefault()
    const { params } = event
    const thisGameId = params.game
    const nextGameId = this.nextMatchingGameId(thisGameId)
    if (thisGameId && nextGameId) {
      const officials = this.selectTargets
        .filter((target) => parseInt(target.dataset.gameId) == parseInt(thisGameId))
        .map((target) => target.value)
      this.copyAssignments(thisGameId, nextGameId, officials)
    } else {
      console.log(`could not find a next game for ${thisGameId}`)
    }
  }

  async copyAll(event) {
    event.preventDefault()
    const { params } = event
    let thisGameId = params.game
    let nextGameId = this.nextMatchingGameId(thisGameId)
    const officials = this.selectTargets
      .filter(
        (target) => parseInt(target.dataset.gameId) == parseInt(thisGameId)
      )
      .map((target) => target.value)
    let iteration = 0
    while (nextGameId) {
      await this.copyAssignments(thisGameId, nextGameId, officials)
      thisGameId = nextGameId
      nextGameId = this.nextMatchingGameId(thisGameId)
      iteration++
    }
  }

  async rotateAll(event) {
    event.preventDefault()
    const { params } = event
    let thisGameId = params.game
    let nextGameId = this.nextMatchingGameId(thisGameId)
    const officials = this.selectTargets
      .filter(
        (target) => parseInt(target.dataset.gameId) == parseInt(thisGameId)
      )
      .map((target) => target.value)
    let iteration = 1
    while (nextGameId) {
      console.log(`${thisGameId} => ${nextGameId}`)
      await this.copyAssignments(
        thisGameId,
        nextGameId,
        this.rotateArray(officials, iteration)
      )
      thisGameId = nextGameId
      nextGameId = this.nextMatchingGameId(thisGameId)
      iteration++
    }
  }

  rotateOne(event) {
    event.preventDefault()
    const { params } = event
    const thisGameId = params.game
    const nextGameId = this.nextMatchingGameId(thisGameId)
    const officials = this.selectTargets
      .filter(
        (target) => parseInt(target.dataset.gameId) == parseInt(thisGameId)
      )
      .map((target) => target.value)

    if (thisGameId && nextGameId) {
      this.copyAssignments(
        thisGameId,
        nextGameId,
        this.rotateArray(officials, 1)
      )
    } else {
      console.log(`could not find a next game for ${thisGameId}`)
    }
  }

  copyButtonTargetConnected(target) {
    console.log('copyButtonTargetConnected', target)
    const gameId = target.dataset.gameId
    if (this.nextMatchingGameId(gameId)) {
      this.showElement(target)
    }
    if (this.proceedingGameCount(gameId) <= 1) {
      this.allTargets
        .filter(
          (element) => parseInt(element.dataset.gameId) == parseInt(gameId)
        )
        .forEach((element) => this.hideElement(element))
    }
  }

  async copyAssignments(thisGameId, nextGameId, officials) {
    if (!nextGameId) {
      console.log(`could not find a next game for ${thisGameId}`)
      return
    }

    const nextGameOutlet = this.assignOutlets.find((outlet) => parseInt(outlet.gameValue) == parseInt(nextGameId))
    if (nextGameOutlet) {
      nextGameOutlet.populate({ params: { preassign: officials.join(",") } })
    }

    // const nextGameSelects = this.selectTargets.filter(
    //   (target) => target.dataset.gameId == parseInt(nextGameId)
    // )

    // if (nextGameSelects.length == 0) {
    //   const assignButton = this.assignButtonTargets.find(
    //     (target) => parseInt(target.dataset.gameId) == parseInt(nextGameId)
    //   )
    //   if (assignButton) {
    //     $.get(`${assignButton.href}.js`).done(() => {
    //       const selects = this.selectTargets.filter(
    //         (target) => target.dataset.gameId == parseInt(nextGameId)
    //       )
    //       officials.forEach((userId, index) => {
    //         if (selects[index]) {
    //           selects[index].value = userId
    //         }
    //       })
    //     })
    //   } else {
    //     console.log('could not find assign button to click...')
    //   }
    //   // click Assign, then copy assignments
    // } else {
    //   officials.forEach((userId, index) => {
    //     if (nextGameSelects[index]) {
    //       nextGameSelects[index].value = userId
    //     }
    //   })
    //   // just copy assignments
    // }

    // this.nextGameId(123)
    // find the next game
    // click 'assign' for next game if needed to show select boxes
    // grab the assigned users for the current game
    // copy the assigne users to the next game
  }

  nextGameId(id) {
    const allGameIds = this.gameTargets.map((target) =>
      parseInt(target.dataset.gameId)
    )
    const gameIndex = allGameIds.findIndex((item) => item == parseInt(id))
    if (gameIndex >= 0) {
      return allGameIds[gameIndex + 1]
    }
  }

  // return next game ID in sequence but only if date/venue match
  nextMatchingGameId(id) {
    const thisGame = this.gameTargets.find(
      (target) => parseInt(target.dataset.gameId) == parseInt(id)
    )
    const nextGame = this.gameTargets.find(
      (target) => parseInt(target.dataset.gameId) == this.nextGameId(id)
    )
    if (thisGame && nextGame && this.gameMatches(thisGame, nextGame)) {
      return parseInt(nextGame.dataset.gameId)
    }
  }

  proceedingGameCount(id) {
    let count = 0
    let nextGameId = this.nextMatchingGameId(id)
    while (nextGameId) {
      count++
      nextGameId = this.nextMatchingGameId(nextGameId)
    }
    return count
  }

  gameMatches(game1, game2) {
    try {
      return (
        game1.dataset.venue == game2.dataset.venue &&
        (game1.dataset.subvenue
          ? game1.dataset.subvenue == game2.dataset.subvenue
          : true) &&
        game1.dataset.date == game2.dataset.date &&
        game1.dataset.venue &&
        game1.dataset.date
      )
    } catch (error) {
      console.log('error matching game', error)
      return false
    }
  }

  rotateArray(array, iteration) {
    const original = array.map((x) => x)
    for (let i = 0; i < iteration; i++) {
      let last = original.pop()
      original.unshift(last)
    }
    return original
  }
}
