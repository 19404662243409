import { ApplicationController } from './application_controller.js'
import TomSelect from 'tom-select'
import { api } from '../components/AxiosHeaders.js'
export default class extends ApplicationController {
  static targets = ['select', 'zoneSelect']
  static values = {
    url: String,
    multiple: Boolean
  }
  connect() {
    const controller = this
    console.log('user-lookup connected!')
    const tsOptions = {
      closeAfterSelect: true,
      hidePlaceholder: true,
      labelField: 'name',
      maxOptions: null,
      searchField: 'name',
      valueField: 'id',
      sortField: [{ field: '$order' }, { field: '$score' }],
      shouldLoad: function (query) {
        return query.length > 1
      },
      render: {
        option: (item, escape) => {
          const { location } = item
          if (location) {
            item.locationDisplay = `(${escape(location)})`
          } else {
            item.locationDisplay = ''
          }
          return `<div data-item-id="${item.id}">
            <strong>${escape(item.name)}</strong> ${item.locationDisplay}
            <br />${escape(item.subheading)}
          </div>`
        }
      },
      onItemAdd: (value, item) => {
        // this clears the textbox value when an item is added
        controller.tomselect.setTextboxValue("");
        controller.tomselect.refreshOptions(false);
      },
      load: (query, callback) => {
        const zoneSelect = controller.hasZoneSelectTarget && controller.zoneSelectTarget
        const zone = zoneSelect && zoneSelect.value
        api
          .get(controller.urlValue, { params: { q: query, zone_id: zone } })
          .then((response) => {
            const { users } = response.data
            callback(users)
          })
          .catch((error) => {
            console.log('error loading users', error)
            callback()
          })
      }
    }

    if (this.multipleValue) {
      tsOptions.plugins = {
        remove_button: {
          title: 'Remove Item'
        }
      }
    }
    this.tomselect = new TomSelect(this.select, tsOptions)
  }

  get select() {
    if (this.hasSelectTarget) {
      return this.selectTarget
    } else {
      return this.element
    }
  }
}
