import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
    static targets = ["checkbox"];

    connect() {
        console.log("User form permissions controller connected");
    }

    sendDataToAlpine() {
        const data = {
            referee: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'official' && checkbox.checked),
            assignor: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'assignor' && checkbox.checked),
            observer: this.checkboxTargets.some(checkbox => checkbox.dataset.roleType === 'readonly' && checkbox.checked),
        }

        const event = new CustomEvent("stimulus:send-data", {
            detail: data,
            bubbles: true,
        });

        this.element.dispatchEvent(event);
    }

    toggleRole(_event) {
        this.sendDataToAlpine();
    }
}