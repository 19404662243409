import { ApplicationController } from "./application_controller.js"
import actionCable from 'actioncable'
export default class extends ApplicationController {
  static targets = [ 'status','scheduled','made','attempted','percent','indicator', 'undo' ]
  connect() {
    var controller = this;
    console.log('hello scheduler')
    this.cable = actionCable.createConsumer(`wss://${window.location.hostname}/cable`)
    // window.moment = moment;
    this.cable.subscriptions.create({
      channel: "SchedulerChannel",
      scheduler: this.element.dataset.scheduler,
      site: this.element.dataset.site
    }, {
      connected: function(){
        console.log('ws connected!')
      },
      disconnected: function(){
        console.log('ws disconnected!')
        controller.indicatorTargets.forEach((target)=>{
          controller.hideElement(target)
        })
      },
      received: function(data){
        console.log('ws data recd', data)
        // status: status,
        // scheduled: assignments_scheduled,
        // made: assignments_made,
        // attempted: assignments_attempted,
        // percent: percent_complete,
        // done: complete?
        if (data.stage == 'complete'){
          controller.undoTargets.forEach((target)=>{
            controller.showElement(target)
          })
        } else {
          controller.undoTargets.forEach((target)=>{
            controller.hideElement(target)
          })

        }
        if (data.done){
          controller.indicatorTargets.forEach((target)=>{
            controller.hideElement(target)
          })
        } else {
          controller.indicatorTargets.forEach((target)=>{
            controller.showElement(target)
          })
        }
        controller.statusTargets.forEach((target)=>{
          target.innerHTML = data.status
        })
        controller.scheduledTargets.forEach((target)=>{
          target.innerHTML = data.scheduled
        })
        controller.madeTargets.forEach((target)=>{
          target.innerHTML = data.made
        })
        controller.attemptedTargets.forEach((target)=>{
          target.innerHTML = data.attempted
        })
        controller.percentTargets.forEach((target)=>{
          target.innerHTML = data.percent
        })
      },
    }) // sub.create({})
  } // connect()
}
