import { ApplicationController } from './application_controller.js'
import { Calendar } from '@fullcalendar/core'
import Cookies from 'js-cookie'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment'
import moment from 'moment-timezone'

import { api } from '../components/AxiosHeaders.js'
import Pikaday from 'pikaday'
import tippy from 'tippy.js'
import { queryString, queryStringToObject } from '../components/QueryString.js'

export default class extends ApplicationController {
  static targets = [
    'calendar',
    'availableButton',
    'allButton',
    'download',
    'picker',
    'timeZone',
    'timeZoneName'
  ]
  static values = {
    defaultDate: String,
    ianaTimeZone: String,
    scrollTime: String,
    timeZones: Object,
    initialTimeZone: String
  }

  goForward() {
    this.calendar.next()
  }
  goBackward() {
    this.calendar.prev()
  }
  openPicker() {}
  goToday() {
    this.calendar.today()
  }

  showAll(event) {
    event.preventDefault()
    this.availableOnly = 0
    this.calendar.refetchResources()
    Cookies.set('availability-show', 'all')
    this.hideElement(this.allButtonTarget)
    this.showElement(this.availableButtonTarget)
    const href = this.downloadTarget.href
    const query = href.split('?')[1] || 'boo=ya'
    console.log(`href: ${href} query: ${query}`)
    let newQuery = queryStringToObject(query)
    newQuery.only_available = ''
    this.downloadTarget.href = `/availability.csv?${queryString(newQuery)}`
  }

  showAvailable(event) {
    event.preventDefault()
    this.availableOnly = 1
    this.calendar.refetchResources()
    Cookies.set('availability-show', 'available')
    this.hideElement(this.availableButtonTarget)
    this.showElement(this.allButtonTarget)
    const href = this.downloadTarget.href
    const query = href.split('?')[1] || 'boo=ya'
    console.log(`href: ${href} query: ${query}`)
    let newQuery = queryStringToObject(query)
    newQuery.only_available = '1'
    this.downloadTarget.href = `/availability.csv?${queryString(newQuery)}`
  }

  lookupTimeZoneName(value) {
    return this.timeZonesValue[value]
  }

  switchTimeZone(event) {
    event.preventDefault()
    const { zone } = event.params
    console.log('tz remembers!', zone)
    Cookies.set('fullcalendar_tz', zone)
    this.calendar.setOption('timeZone', zone)
    this.timeZoneNameTargets.forEach(
      (target) => (target.innerHTML = this.lookupTimeZoneName(zone))
    )
  }

  connect() {
    window.moment = moment
    console.log('calendar', this.calendarTarget)
    console.log('zones:', this.timeZonesValue)
    console.log('initial:', this.initialTimeZoneValue)
    this.currentTimeZone = this.initialTimeZoneValue
    var controller = this
    var picker
    const screenHeight = window.innerHeight
    const elementPosition = this.getPosition(this.calendarTarget).top
    this.calendarTarget.style.height = `${screenHeight - elementPosition}px`
    this.timeZoneNameTargets.forEach(
      (target) =>
        (target.innerHTML = this.lookupTimeZoneName(this.initialTimeZoneValue))
    )
    if (Cookies.get('availability-show') == 'available') {
      this.showElement(this.allButtonTarget)
      this.availableOnly = 1
      if (this.hasDownloadTarget) {
        let href = this.downloadTarget.href
        let query = href.split('?')[1] || 'boo=ya'
        console.log(`href: ${href} query: ${query}`)
        let newQuery = queryStringToObject(query)
        newQuery.only_available = '1'
        this.downloadTarget.href = `/availability.csv?${queryString(newQuery)}`
      }
    } else {
      Cookies.set('availability-show', 'all')
      this.showElement(this.availableButtonTarget)
      this.availableOnly = 0
      if (this.hasDownloadTarget) {
        let href = this.downloadTarget.href
        let query = href.split('?')[1] || 'boo=ya'
        console.log(`href: ${href} query: ${query}`)
        let newQuery = queryStringToObject(query)
        newQuery.only_available = ''
        this.downloadTarget.href = `/availability.csv?${queryString(newQuery)}`
      }
    }
    if (Cookies.get('availability_calendar_now')) {
      var initialDate = moment(
        Cookies.get('availability_calendar_now')
      ).toDate()
    } else {
      var initialDate = moment(this.defaultDateValue).toDate()
    }

    var eventSources = [
      {
        url: '/calendars/games.json',
        extraParams: { availability: 1 },
        eventDataTransform: (event) => {
          const { titleWithoutVenue } = event.extendedProps
          event.title = titleWithoutVenue
          return event
        }
      },
      {
        url: '/calendars/assignments.json',
        extraParams: { availability: 1 }
        // eventDataTransform: (event) => {
        //   const { title: venueSubvenue } = event
        //   const { ageGroup, gender } = event.extendedProps
        //   const ageGender = [ageGroup, gender].filter((a) => a).join(' ')
        //   event.title = `${venueSubvenue}: ${ageGender}`
        //   return event
        // }
      },
      { url: '/calendars/availabilities.json' }
    ]
    var calOptions = {
      expandRows: true,
      height: 'parent',
      schedulerLicenseKey: '0151539399-fcs-1676166803',
      plugins: [momentPlugin, resourceTimelinePlugin, momentTimezonePlugin],
      initialView: 'resourceTimelineDay',
      initialDate: initialDate,
      resourceAreaWidth: '20%',
      slotMinWidth: 60,
      scrollTime: '08:00:00',
      scrollTimeReset: false,
      views: {
        resourceTimelineDay: {
          titleFormat: 'dddd, MMMM D, YYYY'
        }
      },
      headerToolbar: {
        left: 'title',
        right: ''
      },
      // when timeZone is set, calendar shows in the given time zone,
      // when timeZone is NOT set, calendar is shown in the browser's time zone.
      // https://assignr.zendesk.com/agent/tickets/17261 and
      // https://assignr.zendesk.com/agent/tickets/17541
      timeZone: this.initialTimeZoneValue,
      eventSources: eventSources,
      eventSourceSuccess: (rawEvents, response) => {
        const games = rawEvents.filter(
          (event) =>
            event.extendedProps && event.extendedProps.eventType == 'game'
        )
        if (games.length > 0) {
          const earliestTime = games
            .map((event) => moment(event.start).tz(controller.currentTimeZone))
            .reduce((accumulator, currentValue) =>
              Math.min(accumulator, currentValue.hour())
            )
          const newStartTime = Math.min(17, earliestTime)
          console.log(
            `earliestTime: ${earliestTime}, newStartTime: ${newStartTime} ${moment()
              .hours(newStartTime)
              .format('HH:00:00')}`
          )
          // controller.calendar.setOption('scrollTime',  moment().hours(newStartTime).format("HH:00:00"))
          controller.calendar.scrollToTime(
            moment().hours(newStartTime).format('HH:00:00')
          )
        }
        return rawEvents
      },
      resourceOrder: 'lastName,firstName,title,id',
      // datesRender: function (obj) {
      datesSet: ({ view, timeZone }) => {
        const { currentStart } = view
        console.log('datesSet', currentStart, timeZone)
        const href = controller.downloadTarget.href
        const query = href.split('?')[1] || 'boo=ya'
        let newQuery = queryStringToObject(query)
        newQuery.date = moment(currentStart).tz(timeZone).format('YYYY-MM-DD')
        controller.downloadTarget.href = `/availability.csv?${queryString(
          newQuery
        )}`
        const date = currentStart.toUTCString()
        Cookies.set('availability_calendar_now', date)
        if (controller.picker) {
          controller.picker.gotoDate(currentStart)
        }
      },
      resources: function (fetchInfo, successCallback, failureCallback) {
        var venuesData, officialsData
        api
          .get('/calendars/venues.json', {
            params: {
              start: fetchInfo.startStr,
              end: fetchInfo.endStr
            }
          })
          .then((response) => {
            venuesData = response.data.map((venue) => {
              venue.title = venue.venue_subvenue
              return venue
            })
          })
          .then(() => {
            const params = {
              start: fetchInfo.startStr,
              end: fetchInfo.endStr,
              available_only: controller.availableOnly
            }
            return api.get('/calendars/officials.json', { params })
          })
          .then((response) => (officialsData = response.data))
          .then(() => {
            let venues = venuesData.map((venue) =>
              Object.assign(venue, { resourceType: 'Venues' })
            )
            let officials = officialsData.map((official) =>
              Object.assign(official, { resourceType: 'Officials' })
            )
            successCallback(venues.concat(officials))
          })
          .catch((e) => {
            console.log('oops', e)
            failureCallback()
          })
      },
      // eventRender: function (info) {
      eventDidMount: ({ el: element, event, view }) => {
        const {
          titleWithVenue,
          titleWithoutVenue,
          titleWithGameDescription,
          eventType,
          start,
          startTimeShort,
          tz: eventTimeZone
        } = event.extendedProps
        const timeZone = view.calendar.getOption('timeZone')
        const abbreviatedTz =
          eventTimeZone && moment.tz(eventTimeZone).format('z')
        var titleElement = element.querySelector('.fc-event-title')
        
        const tzMatch =
        eventTimeZone ?  moment.tz(eventTimeZone).format('z') ==  moment.tz(timeZone).format('z') : true

        const { title } = event
        if (['game', 'assignment'].includes(eventType)) {
          tippy(element, {
            content: titleWithGameDescription || title,
            allowHTML: true
          })
          if (titleElement && !tzMatch) {
            titleElement.innerHTML = `(${startTimeShort} ${abbreviatedTz}) ${title}`
          }
        }
      },
      resourceLabelDidMount: ({
        el: element,
        resource: resourceApi,
        view,
        fieldValue
      }) => {
        const resource = resourceApi._resource
        const { firstName, lastName, url, resourceType } =
          resource.extendedProps
        if (resourceType == 'Officials' && url) {
          element.style.cursor = 'pointer'
          element.addEventListener('click', () => (window.location.href = url))
        }
      },
      resourceGroupField: 'resourceType',

      refetchResourcesOnNavigate: true,
      customButtons: {
        datepicker: {
          text: 'skip to',
          click: (event) => {
            picker.show()
          }
        }
      }
    }
    console.log('init with opts', calOptions)
    this.calendar = new Calendar(this.calendarTarget, calOptions)
    this.calendar.render()

    // this.calendar.setOption('scrollTime', '08:00:00')
    this.picker = new Pikaday({
      trigger: this.pickerTarget,
      field: this.pickerTarget,
      format: 'yy-mm-dd',
      defaultDate: new Date(initialDate.toUTCString()),
      setDefaultDate: true,
      onSelect: function (date) {
        if (typeof date == 'string') {
          console.log(
            'click! date passed is',
            date,
            new Date(date),
            typeof date,
            JSON.stringify(date)
          )
          controller.picker.gotoDate(new Date(date))
          controller.calendar.gotoDate(new Date(date))
        } else {
          // for some reason, when users in +UTC zones (like Japan at UTC+9)
          // try to navigate using the date picker, the date that is sent to the calendar is the day prior.
          // which is why we are using moment(date).format() to get the LOCAL date for navigation
          // purposes. the date string is passed along to the picker & the calendar, which both seem to
          // work regardless of timezone.
          // https://assignr.zendesk.com/agent/tickets/17261 and
          // https://assignr.zendesk.com/agent/tickets/17541
          console.log(
            'click! typeof date is not string, passed is',
            date,
            date.toISOString(),
            moment(date).format('YYYY-MM-DD')
          )
          controller.picker.gotoDate(moment(date).format('YYYY-MM-DD'))
          controller.calendar.gotoDate(moment(date).format('YYYY-MM-DD'))
        }
      }
    })
    window.pikaday = this.picker
  }

  getPosition(element) {
    const clientRect = element.getBoundingClientRect()
    return {
      left: clientRect.left + document.body.scrollLeft,
      top: clientRect.top + document.body.scrollTop
    }
  }
}
