import { ApplicationController } from "./application_controller.js"
import axios from "axios";
import setAxiosHeaders from '../components/AxiosHeaders'

export default class extends ApplicationController {
  static targets = [ 'archive','checkbox' ]
  connect() {
    console.log("inbox connected", this.element)
    console.log('checkboxes',this.checkboxTargets)
    setAxiosHeaders();
  }
  toggle(e){
    console.log('toggling!',e)
    var checked = false
    this.checkboxTargets.forEach((element,i)=>{
      if (element.checked){
        checked = true
      }
    });
    this.archiveTargets.forEach((archive,i)=>{
      if (checked){
        archive.removeAttribute("disabled")
      } else {
        archive.setAttribute('disabled','disabled')
      }
    })
  }

  all(e){
    this.checkboxTargets.forEach((element,i)=>{
      element.checked = e.target.checked
    });
    this.toggle(e)
  }

  unarchive(e){
    e.preventDefault()
    this.archiveTargets.forEach((archive,i)=>{
      archive.setAttribute('disabled','disabled')
    })
    var controller = this
    this.checkboxTargets.forEach((element,i)=>{
      if (element.checked){
        var messageId = element.dataset.message
        console.log(messageId, element.dataset)
        axios.put("/messages/" + messageId + ".js", {
          inbox_recipient: {
            archived: false
          }
        }).then(function (response) {
          console.log(response);
          var row = element.closest("tr")
          if (row){
            controller.hideElement(element)
            controller.toggle()
            e.target.checked = false
          }
        })
      }
    });
  }
  archive(e){
    e.preventDefault()
    this.archiveTargets.forEach((archive,i)=>{
      archive.setAttribute('disabled','disabled')
    })
    var controller = this
    this.checkboxTargets.forEach((element,i)=>{
      if (element.checked){
        var messageId = element.dataset.message
        console.log(messageId, element.dataset)
        axios.put("/messages/" + messageId + ".js", {
          inbox_recipient: {
            archived: true
          }
        }).then(function (response) {
          console.log(response);
          var row = element.closest("tr")
          if (row){
            row.remove()
            controller.toggle()
            e.target.checked = false
          }
        })
      }
    });
  }
}
