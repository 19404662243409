import { ApplicationController } from './application_controller.js'
import axios from 'axios'
import { api } from '../components/AxiosHeaders.js'
export default class extends ApplicationController {
  static targets = [
    'form',
    'routing',
    'account',
    'confirmation',
    'name',
    'type',
    'submit'
  ]
  static values = {
    tokenUrl: String,
    fundingSourceUrl: String,
    successUrl: String
  }
  connect() {
    const controller = this
    controller.disable()
    api
      .post(this.tokenUrlValue)
      .then((resp) => {
        const { token, microdeposits } = resp.data
        controller.token = token
        controller.microdeposits = microdeposits
        controller.enable()
        console.log('token successfully retrieved')
        console.log("tokens", resp.data)
      })
      .catch((error) => {
        console.log('error', error)
        alert(
          'Could not initialize this form due to a server error. Please refresh the page and try again.'
        )
      })
  }

  create(event) {
    const controller = this
    event.preventDefault()
    this.disable()
    const validated = this.validate()
    if (validated) {
      axios
        .post(
          this.fundingSourceUrlValue,
          {
            routingNumber: this.routingTarget.value,
            accountNumber: this.accountTarget.value,
            bankAccountType: this.accountType,
            name: this.nameTarget.value
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              Accept: 'application/vnd.dwolla.v1.hal+json'
            }
          }
        )
        .then((fsResponse) => {
          // console.log(`fsResponse.headers: `, fsResponse.headers)
          // console.log(`fsResponse: `, fsResponse)
          // console.log('controller.microdeposits', controller.microdeposits)
          // alert('check the logs!')
          if (controller.microdeposits) {
            const { location } = fsResponse.headers
            const url = `${location}/micro-deposits`
            console.log('fs url', url)
            const axiosRequest = {
              method: 'post',
              url: url,
              headers: {
                Authorization: `Bearer ${controller.microdeposits}`,
                Accept: 'application/vnd.dwolla.v1.hal+json'
              }
            }
            console.log('axiosRequest', axiosRequest)
            return axios(axiosRequest)
              .then((resp) => {
                console.log('md success!')
                return fsResponse
              })
              .catch((error) => {
                console.log('no dice', error)
                return Promise.reject(error)
              })
          }
          return fsResponse
        })
        .then((resp) => {
          console.log('success!')
          console.log(resp.data)
          if (controller.successUrlValue) {
            window.location.href = controller.successUrlValue
          }
        })
        .catch((error) => {
          console.log('error', error, error.response)
          if (error.response) {
            const { data } = error.response || {}
            const { code, _embedded } = data
            if (code == 'DuplicateResource') {
              window.location.href = controller.successUrlValue
              return
            }
            if (code == 'InvalidAccessToken') {
              alert(
                'Your bank account information could not be submitted. Please refresh the page and try again. '
              )
              return
            }
            if (_embedded && _embedded.errors) {
              alert(
                `Your bank account information could not be submitted: ${_embedded.errors[0].message}`
              )
            } else if (data.message) {
              alert(
                `Your bank account information could not be submitted: ${data.message}`
              )
            } else {
              alert(
                'Your bank account information could not be submitted. Please refresh the page and try again. '
              )
            }
          }
        })
        .finally(() => controller.enable())
    } else {
      this.enable()
    }
  }

  disable() {
    const inputs = this.formTarget.getElementsByTagName('input')
    Array.from(inputs).forEach((element) => (element.disabled = true))
  }

  enable() {
    const inputs = this.formTarget.getElementsByTagName('input')
    Array.from(inputs).forEach((element) => (element.disabled = false))
  }

  validate() {
    console.log('accountType', this.accountType)
    if (!['checking', 'savings'].includes(this.accountType)) {
      alert('Please choose Savings Account or Checking Account')
      return false
    }
    if (!this.routingTarget.value) {
      alert('Please enter a routing number.')
      return false
    }
    if (!this.accountTarget.value) {
      alert('Please enter a account number.')
      return false
    }
    if (!this.confirmationTarget.value) {
      alert('Please re-enter your account number.')
      return false
    }
    if (!this.nameTarget.value) {
      alert('Please enter the name of your account..')
      return false
    }
    if (this.confirmationTarget.value != this.accountTarget.value) {
      alert(
        'Please re-type your account number. The numbers provided do not match.'
      )
      return false
    }
    return true
  }
  get accountType() {
    console.log('typeTargets', this.typeTargets)
    try {
      return this.typeTargets.find((target) => target.checked).value
    } catch (error) {
      return null
    }
  }
}
