import { ApplicationController } from "./application_controller.js"
import Pikaday from 'pikaday'
import moment from 'moment'
import * as chrono from 'chrono-node';
import translations from '../components/PikadayTranslations.js';
const MOMENT_DATE_FORMAT = document.querySelector("meta[name='assignr:moment-date-format']").getAttribute('content')
const DATE_ORDER = JSON.parse(document.querySelector("meta[name='assignr:locale-date-order']").getAttribute('content'))

export default class extends ApplicationController {
  static targets = [ 'picker' ]
  static values = {
    skipParse: Boolean
  }
  connect() {
    this.pikas = []
    this.dateFormat = MOMENT_DATE_FORMAT
    if (this.hasPickerTarget){
      this.pickerTargets.forEach((e, i) => {
        this.installPika(e);
      });
    } else {
      this.installPika(this.element)
    }
  }

  setdate(event){
    event.preventDefault();
    if (this.hasPickerTarget){
      this.pickerTargets.forEach((e, i) => {
        e.value = event.target.dataset.date
      });
    }
  }

  installPika(element){
    console.log('initialize pika on', element)
    element.classList.add('form-control')
    if (element.value){
      var invalidMatcher = /invalid/i
      var parsed = moment(element.value, ['YYYY-MM-DD', this.dateFormat], true).format(this.dateFormat);
      if (!invalidMatcher.test(parsed) ){
        element.value = parsed
      }
    }
    const { localData } = translations;
    let i18n = localData();
    const week =  document.querySelector("meta[name='week-starts-on']")
    const firstDay = week ? parseInt(week.content) : 0 ;
    let pikadayOptions = {
      field: element,
      format: this.dateFormat,
      i18n: i18n,
      firstDay: firstDay
    }
    const parser = (dateString, format) => {
      console.log("parser called with", dateString, format)
      let parsed = dateString.replace(/[.\-]/g, '/')
      let customChrono = chrono
      if (DATE_ORDER[0] == 'day') {
        customChrono = chrono.en.GB
      }
      let date = customChrono.parseDate(parsed) || chrono.parseDate(parsed); // en fallback for 'today', 'tomorrow', 'yesterday'
      return date
    }
    if (!this.skipParseValue){
      pikadayOptions.parse = parser
    }
    var picker = new Pikaday(pikadayOptions);

    this.pikas.push(picker)
  }
}
