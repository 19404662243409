import TomSelect from "tom-select";
import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
    connect() {
        console.log('game_edit_assign_searchable initialized');

        const preSelectedValue = this.element.value;

        this.tomSelect = new TomSelect(this.element, {
            placeholder: '« Unassigned »',
            plugins: ['clear_button'],
            render: {
                item: (data, escape) => `
                    <div>
                        <div style="margin-left: 10px; display: flex; flex-flow: row wrap; column-gap: 5px; align-items: center">
                            <div style="flex: 6 0 0">
                                ${escape(data.text)}
                            </div>
                        </div>
                    </div>
                `,
                option: (data, escape) => `
                    <div>
                        ${escape(data.text)}
                    </div>
                `
            },
            maxOptions: null,
            allowEmptyOption: true,
            items: preSelectedValue ? [preSelectedValue] : [],
            onInitialize: function () {
                if (!preSelectedValue || preSelectedValue === "") {
                    this.clear(true);
                    this.refreshOptions(false);
                }
            }
        });

        // If there's no pre-selected value, clear the selection after a short delay
        if (!preSelectedValue || preSelectedValue === "") {
            setTimeout(() => {
                this.tomSelect.clear(true);
                this.tomSelect.refreshOptions(false);
            }, 0);
        }
    }

    disconnect() {
        if (this.tomSelect) {
            this.tomSelect.destroy();
        }
    }
}
