import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = [ 'more','other' ]
  connect() {
    console.log("plus more connected", this.element)
  }
  toggle(){
    this.moreTargets.forEach((e,i)=>{
      this.hideElement(e)
    })
    this.otherTargets.forEach((e,i)=>{
      this.showElement(e)
    })
  }
}
