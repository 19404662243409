import { ApplicationController } from './application_controller.js'
import { api } from '../components/AxiosHeaders'
const googClientMeta = document.querySelector('meta[name="yek-elgoog"]')
const googClientKey = googClientMeta ? googClientMeta.content : 'no-key'

export default class extends ApplicationController {
  static targets = ['map', 'latitude', 'longitude', 'location', 'timezone']
  connect() {
    var marker
    this.google = window.google
    this.autocomplete = new this.google.maps.places.Autocomplete(
      this.locationTarget,
      {
        types: ['(cities)'],
        fields: [
          'place_id',
          'geometry',
          'utc_offset_minutes',
          'address_components'
        ]
      }
    )
    this.coords = {}
    console.log('dataset', this.element.dataset)
    console.log('this.latitudeTarget.value', this.latitudeTarget.value)
    console.log('this.longitudeTarget.value', this.longitudeTarget.value)
    if (this.latitudeTarget.value == '') {
      this.coords.lat = parseFloat(this.element.dataset.latitude)
    } else {
      this.coords.lat = parseFloat(this.latitudeTarget.value)
    }
    if (this.longitudeTarget.value == '') {
      this.coords.lng = parseFloat(this.element.dataset.longitude)
    } else {
      this.coords.lng = parseFloat(this.longitudeTarget.value)
    }
    console.log('coords', this.coords)
    var map = new google.maps.Map(this.mapTarget, {
      center: this.coords,
      zoom: 8
    })

    if (this.latitudeTarget.value) {
      marker = new google.maps.Marker({
        position: this.coords,
        map
      })
    }

    this.autocomplete.setFields(['address_component', 'geometry'])
    var controller = this
    this.autocomplete.addListener('place_changed', () => {
      var place = controller.autocomplete.getPlace()
      console.log(place)
      map.setCenter(place.geometry.location)
      map.setZoom(9)
      if (marker) {
        marker.setPosition(place.geometry.location)
      } else {
        marker = new google.maps.Marker({
          position: place.geometry.location,
          map
        })
      }
      controller.latitudeTarget.value = place.geometry.location.lat()
      controller.longitudeTarget.value = place.geometry.location.lng()
      const params = new URLSearchParams({
        location: [
          place.geometry.location.lat(),
          place.geometry.location.lng()
        ].join(','),
        timestamp: Math.floor(new Date().getTime() / 1000),
        key: googClientKey
      })
      api
        .post('/zones/timezone.json', {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        })
        .then((resp) => {
          console.log(resp.data)
          const { name } = resp.data
          if (this.hasTimezoneTarget) {
            this.timezoneTarget.value = name
          }
        })
        .catch((error) => console.log('error!', error))
    })
  }
}
