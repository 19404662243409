import { ApplicationController } from "./application_controller.js"
import tippy from 'tippy.js'

export default class extends ApplicationController {
  static values = {
    codes: String
  }
  connect(){
    tippy(this.element, {
      delay: [0,1000],
      duration: [125,500],
      content: this.codesValue,
      allowHTML: true,
      hideOnClick: true,
      trigger: 'click',
    })
  }
}
