import { ApplicationController } from './application_controller.js'
import TomSelect from 'tom-select'
export default class extends ApplicationController {
  static targets = ['select']
  
  connect() {
    this.build()
  }

  build() {
    const options = Array.from(this.select.getElementsByTagName('option'))
    this.allValues = options.map((element) => element.value)
    const controller = this 
    console.log('allValues', this.allValues)
    
    const tsOptions = {
      maxOptions: null,
      hidePlaceholder: true,
      copyClassesToDropdown: false,
      sortField: [{ field: '$order' }, { field: '$score' }],
      onItemAdd: (value, item) => {
        // this clears the textbox value when an item is added
        controller.tomselect.setTextboxValue("")
        controller.tomselect.refreshOptions(true)
      },
      plugins: {
        remove_button: {
          title: 'Remove Item'
        }
      }
    }
    this.select.classList.remove("form-control")
    console.log(`rebuilding ${this.select.id}, values are`, this.values)
    if (this.values){
      tsOptions.items = this.values
      console.log("rebuilding values from ", this.values)
      const selected = this.select.querySelectorAll('option')
      Array.from(selected).forEach((el) => {
        el.checked = this.values.includes(el.value);
        if (this.values.includes(el.value)){
          console.log("=> checked for", el.value, el.checked)
        }
      });  
    }
    this.tomselect = new TomSelect(this.select, tsOptions)
  }

  revert(){
    const selected = this.select.querySelectorAll('option:checked')
    this.values = Array.from(selected).map(el => el.value);
    console.log(`reverting ${this.select.id}, values are`, this.values)
    if (this.tomselect){
      this.tomselect.destroy()        
    }
  }
  rebuild(){
    console.log(`select value for ${this.select.id} is`, this.select.value)
    // console.log(`select values for ${this.select.id} is`, values)
    // const value = this.select.value 
    if (this.tomselect){
      this.tomselect.destroy()        
    }
    // this.build()
    // this.tomselect.setValue(value, true)
  }

  get select() {
    if (this.hasSelectTarget) {
      return this.selectTarget
    } else {
      return this.element
    }
  }

  selectAll(event) {
    console.log('selectAll!')
    event.preventDefault()
    this.tomselect.setValue(this.allValues)
  }
  selectNone(event) {
    console.log('selectNone!')
    event.preventDefault()
    this.tomselect.setValue([])
  }
}
