import { ApplicationController } from "./application_controller.js"
import Sortable from 'sortablejs';
import { api } from "../components/AxiosHeaders"
export default class extends ApplicationController   {
  static targets = ["container","handle","sortable"]
  static values = {
    url: String
  }
  connect() {
    console.log('rtt')
    const controller = this 
    console.log('container', this.containerTargets)
    console.log('handles', this.handleTargets)
    const sortable = new Sortable(this.containerTarget, {
      handle: '.drag-handle',
      onEnd: () => {
        const sorted = controller.sortableTargets.map((el) => el.dataset.sortable_id)
        const data = {
          reordered_ids: sorted
        }
        console.log("data",data)
        api.post(controller.urlValue, data, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        }).then((resp) => console.log(resp))

      }
    });

    // this.drake = dragula(this.containerTargets,config)
    //   .on("drop",function(el, target, source, sibling){
    //     var containers = [target];
    //     if (target.id != source.id){
    //       containers.push(source);
    //     }
    //     containers.forEach((container, i) => {
    //       var url = container.dataset.reorderUrl
    //     })
    //   });
    // window.drake = this.drake
  }
  handleTargetConnected(element){
    element.style.cursor = 'grab'
  }
}

