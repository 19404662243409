import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = ['id','name','dob','email','search',
    'idField', 'firstNameField','lastNameField','dobField','emailField']
  connect(){
    console.log('connected ussf search',this.element)
    this.showBlocks()
  }

  showBlocks(){
    this.idTargets.forEach((e) => this.hideElement(e))
    this.nameTargets.forEach((e) => this.hideElement(e))
    this.emailTargets.forEach((e) => this.hideElement(e))
    this.dobTargets.forEach((e) => this.hideElement(e))
    if (this.hasSearchTarget){
      switch (this.searchTarget.value) {
        case 'email':
          this.emailTargets.forEach((e) => this.showElement(e))
          break;
        case 'id_dob':
          this.idTargets.forEach((e) => this.showElement(e))
          this.dobTargets.forEach((e) => this.showElement(e))
          break;
        case 'name_dob':
          this.nameTargets.forEach((e) => this.showElement(e))
          break;
        case 'id':
          this.idTargets.forEach((e) => this.showElement(e))
          break;
        default:
      }
    }
  }

  submit(e){
    if (!this.validate()){
      e.preventDefault()
    }
  }

  validate(){
    const present = (value) => {
      try {
        return !!(value.toString().length > 0)
      } catch (e) {
        return false
      }
    }
    if (this.hasSearchTarget){
      switch (this.searchTarget.value) {
        case 'email':
          if (this.hasEmailFieldTarget && this.emailFieldTarget.value){
            return true
          } else {
            alert("Email is required.");
            return false;
          }
          break;
        case 'id_dob':
          if (present(this.dobFieldTarget.value) && present(this.idFieldTarget.value)){
            return true
          } else if (present(this.dobFieldTarget.value)){
            alert("USSF ID is required")
            return false
          } else if (present(this.idFieldTarget.value)){
            alert("Date of Birth is required")
            return false
          } else {
            alert("Please provide USSF ID and Date of Birth.")
            return false
          }
          break;
        case 'name_dob':
          if (this.dobFieldTarget.value && this.firstNameFieldTarget.value &&  this.lastNameFieldTarget.value){
            return true
          } else {
            alert("Please provide name and date of birth.")
            return false
          }
          // this.nameTargets.forEach((e) => this.showElement(e))
          break;
        case 'id':
          if (this.idFieldTarget.value){
            if (this.idFieldTarget.value.toString().replace(/[^0-9]/g,'').length == 16){
              return true
            } else {
              alert("USSF ID must be a 16 digit number.")
              return false
            }
          } else {
            alert("Please provide a valid USSF ID.")
            return false
          }

          // this.idTargets.forEach((e) => this.showElement(e))
          break;
        default:
      }

    }
    return false
  }

}
