import { ApplicationController } from "./application_controller.js"
import $ from 'jquery';

export default class extends ApplicationController {
    static targets = [
        'pushCheckbox', 'pushBody', 'pushBodyLength', 'pushBlock', 'messageEditor',
        'toGlobalIdsSelect', 'bccGlobalIdsSelect', 'submit', 'form'
    ]
    static values = { pushLimit: Number }

    connect() {
        this.togglePushBody()
        this.pushBodyChanged = false
        console.log("connected: new-message")
        this.element.addEventListener('trix-change', (event) => {
            this.disableSubmitIfTrixAttachmentsUploading();

            if (this.pushBodyChanged) {
                return
            }
            console.log('trix-change', event)
            let newText = event.target.innerText.toString()
                .replace(/[\=\-\_\#\*]{8,}.*/gs, '')
                .replace(/[\n\r\t]/gi, ' ')
                .replace(/\s+/g, ' ')
                .trim()
            if (newText.length > this.pushLimitValue) {
                newText = newText.substring(0, this.pushLimitValue - 3) + "..."
            }
            this.pushBodyTarget.value = newText
            this.updateLength()
            console.log("new text", this.pushBodyTarget.value)
        });

        // Search by distance form
        const toForm = document.getElementById('distance-to_global_ids-form');
        const bccForm = document.getElementById('distance-bcc_global_ids-form');
        toForm.addEventListener("submit", event => {
            const formData = new FormData(event.target);
            this.submitDistanceForm(formData, "to_global_ids", $(this.toGlobalIdsSelectTarget).val());
            event.preventDefault();
        });
        bccForm.addEventListener("submit", event => {
            const formData = new FormData(event.target);
            this.submitDistanceForm(formData, "bcc_global_ids", $(this.bccGlobalIdsSelectTarget).val());
            event.preventDefault();
        });
    }

    disableSubmitIfTrixAttachmentsUploading() {
        const { hasTrixAttachmentsUploading } = this;
        console.log(this.submitTargets);
        this.submitTargets.forEach(submitTarget => submitTarget.disabled = hasTrixAttachmentsUploading)
    }

    get hasTrixAttachmentsUploading() {
        return this.trixAttachments.some(attachment => attachment.isPending())
    }

    get trixAttachments() {
        return this.trixElements.flatMap(trix => trix.editor.getDocument().getAttachments())
    }

    get trixElements() {
        return Array.from(this.element.querySelectorAll("trix-editor"))
    }

    submitDistanceForm(formData, fieldName, recipients) {
        recipients.forEach(recipient => {
            formData.append("recipients[]", recipient);
        });
        fetch('/messages/select_by_distance', {
            body: formData,
            method: "POST"
        }).then(response => response.json())
            .then(data => {
                $(`#distance-${fieldName}-modal`).modal('hide');
                $(`#inbox_message_${fieldName}`).val(data).change();
            }).catch(e => {
                console.log(e);
            });
    }

    togglePushBody() {
        if (this.pushCheckboxTarget.checked) {
            this.pushBlockTargets.forEach((element, i) => {
                this.showElement(element)
            });
        } else {
            this.pushBlockTargets.forEach((element, i) => {
                this.hideElement(element)
            });
        }
    }

    stopAutoFill() {
        this.pushBodyChanged = true
    }

    updateLength() {
        try {
            let letterCount = this.pushBodyTarget.value.length
            this.pushBodyLengthTargets.forEach((element, i) => {
                element.innerHTML = this.pushBodyTarget.value.length
                if (letterCount > this.pushLimitValue) {
                    this.addClass(element, 'limit-exceeded')
                } else {
                    this.removeClass(element, 'limit-exceeded')
                }

            })
        } catch (e) {
            console.log('error updating length', e)
        }
    }
}
