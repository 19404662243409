import bootstrap3 from '@formio/bootstrap3'
const fontAwesomeIconClass = (iconset, name, spinning) => {
  switch (name) {
    case 'font':
    case 'html5':
        name = 'font-case';
      break;
    case 'save':
      name = 'download';
      break;
    case 'zoom-in':
      name = 'search-plus';
      break;
    case 'zoom-out':
      name = 'search-minus';
      break;
    case 'question-sign':
      name = 'question-circle';
      break;
    case 'remove-circle':
      name = 'times-circle-o';
      break;
    case 'new-window':
      name = 'window-restore';
      break;
    case 'move':
      name = 'arrows';
      break;
    case 'time':
      name = 'clock-o';
      break;
  }
  const icons = new Set(name.split(' ').map((str) => `fa-${str.replace(/^fa-/,'')}`))
  icons.add('fa-regular')
  if (spinning) {icons.add('fa-spin')}
  return [...icons].join(" ")
}
bootstrap3.templates.bootstrap3.iconClass = fontAwesomeIconClass
export default bootstrap3
