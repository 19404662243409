import { ApplicationController } from './application_controller.js'
import TomSelect from 'tom-select'
import { api } from '../components/AxiosHeaders.js'
import ClearPlugin from '../components/clear_plugin'
TomSelect.define('clear_plugin2', ClearPlugin);
export default class extends ApplicationController {
  static targets = [
    'subvenue',
    'venue',
  ]
  static values = {
    addAgeGroup: Boolean,
    addGameType: Boolean,
    addGender: Boolean,
    addLeague: Boolean,
    addTeam: Boolean,
    addVenue: Boolean,
  }

  connect() {
    console.log('game_entry initialized', this.element)
    const renderItem = (
      data,
      escape
    ) => { 
      console.log("data", data)
      return `<div style="display: flex; flex-direction: row; gap: 5px">
            <i class="fa-solid fa-caret-down"></i> 
            <span>${escape(data?.text)}</span>
          </div>`}
    const tsOptions = {
      plugins: ['clear_plugin2'],
      // plugins: {
      //   clear_button: {
      //     html: (
      //       data
      //     ) => `<div style="flex: 1; display: flex; flex-direction: row; justify-content: flex-end;">
      //       <div style="align-self: flex-end; padding-right: 5px" class="${data.className} fa-solid fa-xmark fa-color-danger"></div>
      //     </div>`
      //   }
      // },
      render: {
        item: renderItem,
      },
      maxOptions: null
    }
    const controller = this 
    controller.venueSubvenues = []
    this.venueSelect = new TomSelect(this.venueTarget, {
      ...tsOptions,
      create: (this.addVenueValue && this.addVenue.bind(this)),
    })
    this.subvenueSelect = new TomSelect(this.subvenueTarget, {
      ...tsOptions,
      maxItems: 1,
      createOnBlur: true,
      create: (input)=> {
        if (controller.venueSubvenues.length == 0){
          // when there are no defined subvenues for the venue, allow user to create one, and clear any other options
          controller.subvenueSelect.clearOptions()
          return {value: input, text: input}
        } else {
          return false
        }
      },
      render: {
        item: renderItem
      }
    })
    this.getSubvenues()
  }

  addVenue(input, callback){
    console.log(`creating new venue "${input}", zone ${this.zoneValue}...`)
    api
      .post('/venues.json', {
        venue: {
          name: input,
          zone_id: this.zoneValue
        }
      })
      .then((response) => {
        console.log('created!', response.data)
        const { id, name } = response.data
        var t = document.createElement('template');
        t.innerHTML = `<p class="help-block">Add a Street Address for <a href="/venues/${id}/edit" target="blank">${name}</a></p>`;
        this.venueTarget.parentNode.appendChild(t.content);
        const subvenueTs = this.subvenueTarget.tomselect
        subvenueTs.clear()
        callback({
          id: id.toString(),
          value: id.toString(),
          text: name
        })
      })
      .catch((error) => {
        const { response, request } = error
        try {
          const message = error.response.data._embedded.errors.map((error) => error.message).join(', ')
          alert(`Could not create Venue: ${input} ${message}.`)
        } catch (e) {
          alert(`Could not create Venue ${input}.`)          
        }
        console.log('error creating new venue', error)
        console.log('response', response)
        console.log('request', request)
        callback()
      })
  }

  getSubvenues() {
    const venueId = this.venueTarget.value
    console.log(`venue changed to ${venueId}`)
    const controller = this
    if (venueId) {
      api.get(`/venues/${venueId}.json`).then((response) => {
        const venue = response.data
        const {subvenues} = venue
        controller.venueSubvenues = subvenues
        controller.subvenueSelect.clearOptions()
        
        if (subvenues.length > 0) {
          controller.subvenueSelect.createOnBlur = false
          console.log('found subvenues',subvenues)
          if (controller.subvenueTarget.value && !subvenues.find((subvenue) => subvenue === controller.subvenueTarget.value)) {
            console.log("clearing selection, did not find ", controller.subvenueTarget.value)
            controller.subvenueSelect.clear()
          }
          controller.subvenueSelect.clearOptions()
          controller.subvenueSelect.addOptions(subvenues.map((subvenue) => ({value:subvenue, text:subvenue})))  
        } else {
          controller.subvenueSelect.createOnBlur = true
          // venue has no defined subvenues
        } 
      })
    } else {
      this.subvenueSelect.clear()
      this.subvenueSelect.clearOptions()
      //venue was cleared or is undefined
    }
  }


  reloadDependencies() {
    console.log('reloadDependencies!')
    // this.ageSelect.trigger('load')
    // this.ageSelect.refreshOptions()
  }
}
