import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = [ 'checkbox', 'button', 'count' ]
  connect() {
    console.log("table-checkboxes", this.element,this.countTargets)
    this.toggleButtons()
  }

  toggleAll(event){
    const masterCheckbox = event.target 
    this.checkboxTargets.forEach((target) => target.checked = masterCheckbox.checked)
    this.toggleButtons()
  }

  toggleButtons(){
    const anyChecked = !!this.checkboxTargets.find((target) => target.checked)
    const checkedCount = this.checkboxTargets.filter((target) => target.checked).length
    this.buttonTargets.forEach((target) => target.disabled = !anyChecked)
    this.countTargets.forEach((target) => target.innerHTML = checkedCount)
  }
}
