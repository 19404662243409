import { ApplicationController } from './application_controller.js'

export default class extends ApplicationController {
  static targets = ['checkbox', 'original', 'assigned']

  connect() {
    this.originalTargets.forEach((target) => this.showElement(target))
    this.assignedTargets.forEach((target) => this.hideElement(target))
  }

  assign(event) {
    console.log('event', event)
    const { params, target } = event
    const { assignment } = params
    if (target.checked) {
      console.log('checked!')
      this.checkboxTargets
        .filter((target) => target.dataset.assignment != assignment)
        .forEach((target) => (target.checked = false))
      this.originalTargets.forEach((target) => {
        if (target.dataset.assignment == assignment) {
          this.hideElement(target)
        } else {
          this.showElement(target)
        }
      })
      this.assignedTargets.forEach((target) => {
        if (target.dataset.assignment == assignment) {
          this.showElement(target)
        } else {
          this.hideElement(target)
        }
      })
    } else {
      this.originalTargets.forEach((target) => this.showElement(target))
      this.assignedTargets.forEach((target) => this.hideElement(target))
    }
  }
}
