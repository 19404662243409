import { ApplicationController } from "./application_controller.js"
import tippy from 'tippy.js'

export default class extends ApplicationController {
  static values = {
    content: String,
    html: Boolean,
    noDelay: Boolean,
    delay: Boolean,
    options: Object,
  }
  connect(){
    tippy(this.element, this.tippyConfig)
    if (!this.hasContentValue){
      this.element.removeAttribute('title')
    }
  }

  get tippyConfig(){
    let config = {
      duration: [125,500],
      content: this.contentValue || this.element.title,
      allowHTML: this.htmlValue,
    }
    if (this.delayValue){
      config.delay = [0,500]
    }
    return {...config, ...this.optionsValue};
  }
}
