import bootstrap3 from '../components/FormioBootstrap3Template'
import { Formio } from 'formiojs'

import { ApplicationController } from './application_controller.js'
export default class extends ApplicationController {
  static targets = ['builder', 'form', 'json']
  static values = {
    components: Object,
    basicComponents: Object,
    template: Object
  }
  connect() {
    const controller = this
    Formio.use(bootstrap3)
    window.formIoBs3 = bootstrap3
    Formio.icons = 'fontawesome';
    Formio.Templates.framework = 'bootstrap3'
    console.log('formio-input', this.element)
    console.log('template', this.templateValue)
    console.log('components', this.basicComponentsValue)

    if (!this.hasBuilderTarget) {
      console.log('builderTarget not found')
      return
    }
    this.formChanged = false
    this.formTarget.dataset.changed = false
    Formio.builder(this.builderTarget, this.templateValue, {
      disabled: ['password'],
      builder: {
        premium: false,
        basic: false,
        basic_without_password: {
          title: 'Basic',
          weight: 0,
          default: true,
          components: this.basicComponentsValue,
        },
        custom: {
          title: 'Game Report',
          weight: 50,
          components: this.componentsValue
        }
      }
    }).then((builder) => {
      console.log('builderInstance.ready')
      controller.builder = builder
      builder.on('change', () => {
        window.builderSchema = builder.schema
        console.log('onBuild fires with', builder.schema)
        controller.jsonTarget.value = JSON.stringify(builder.schema)
        controller.formChanged = true
      })
    })
    this.formTarget.addEventListener('submit', (event) => {
      controller.formChanged = false
    })
    window.addEventListener('beforeunload', function (e) {
      console.log('beforeunload listener fires')
      if (controller.formChanged) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    })
  }
}
