import { ApplicationController } from './application_controller.js'
import { sum, inputToInteger, intToCurrency } from '../components/Financials'
import $ from 'jquery'
export default class extends ApplicationController {
  static outlets = ['approve-game']
  static targets = [
    'approveCheckbox',
    'assignmentApproved',
    'assignmentTotal',
    'feeApproved',
    'feeTotal',
    'form',
    'gameApproved',
    'gameTotal',
    'payor',
    'payorSelect',
    'payorModal',
    'total',
  ]

  changeAllPayors(event){
    event.preventDefault()
    if (this.hasPayorSelectTarget){
      this.approveGameOutlets.forEach((outlet) => {
        outlet.showPayor()
        outlet.element.dataset.approveGamePayorValue = this.payorSelectTarget.value
        outlet.payorSelectTargets.forEach((target) => {
          target.value = this.payorSelectTarget.value
        } )
        outlet.populate()
      })
      this.recalculate()
      // setTimeout(this.recalculate.bind(this), 500)
      if (this.hasPayorModalTarget){
        $(this.payorModalTarget).modal("hide")
      }  
    }
  }

  connect() {
    console.log('ag outlet', this.payorTargets)
    setTimeout(this.recalculate.bind(this), 500)
    this.formTargets.forEach((target) => {
      target.addEventListener('keydown', (e) =>  {
        if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
            if (e.target.nodeName == 'INPUT' && e.target.type !== 'textarea') {
                e.preventDefault();
                return false;
            }
        }
      }, true);
    })
  }

  checkAllBoxes(event){
    event.preventDefault()
    this.approveCheckboxTargets.forEach((target) => target.checked = true)
    this.approveGameOutlets.forEach((outlet) => outlet.populate())
    this.recalculate()
  }
  
  uncheckAllBoxes(event){
    event.preventDefault()
    this.approveCheckboxTargets.forEach((target) => target.checked = false)
    this.approveGameOutlets.forEach((outlet) => outlet.populate())
    this.recalculate()
  }

  recalculate() {
    this.payorTargets.forEach((payorTarget) => {
      const payorID = payorTarget.dataset.payor
      const games = this.approveGameOutlets.filter(
        (gameOutlet) => gameOutlet.payorValue == payorID
      )
      this.populateContainer(games, payorTarget)
    })
    if (this.hasTotalTarget) {
      const games = this.approveGameOutlets
      this.populateContainer(games, this.totalTarget)
    }
  }

  recalculatePayor(payorID) {
    this.payorTargets
      .filter((target) => target.dataset.payor == payorID)
      .forEach((payorTarget) => {
        const games = this.approveGameOutlets.filter(
          (gameOutlet) => gameOutlet.payorValue == payorID
        )
        this.populateContainer(games, payorTarget)
      })
  }

  recalculateTotals() {
    if (this.hasTotalTarget) {
      const games = this.approveGameOutlets
      this.populateContainer(games, this.totalTarget)
    }
  }

  populateContainer(games, container) {
    const feeTotalAmount = games
      .map((game) => game.gameTotal())
      .reduce(sum, 0.0)
    const approvedFeeTotalAmount = games
      .map((game) => game.approvedGameTotal())
      .reduce(sum, 0.0)
    const gameCount = games.length
    const approvedGameCount = games.filter((game) => game.gameApproved).length
    const assignmentCount = games
      .map((game) => game.assignmentCount)
      .reduce(sum, 0)
    const approvedAssignmentCount = games
      .map((game) => game.approvedAssignmentCount)
      .reduce(sum, 0)

    this.populate(
      this.feeTotalTargets,
      container,
      intToCurrency(feeTotalAmount)
    )
    this.populate(
      this.feeApprovedTargets,
      container,
      intToCurrency(approvedFeeTotalAmount)
    )
    this.populate(this.gameTotalTargets, container, gameCount)
    this.populate(this.gameApprovedTargets, container, approvedGameCount)
    this.populate(this.assignmentTotalTargets, container, assignmentCount)
    this.populate(
      this.assignmentApprovedTargets,
      container,
      approvedAssignmentCount
    )
  }

  populate(targets, payor, value) {
    targets.forEach((target) => {
      if (payor.contains(target)) {
        target.innerHTML = value
      }
    })
  }

  approveGameOutletConnected(outlet, element) {
    element.addEventListener(
      'approve:recalculate',
      this.handleRecalculationEvent.bind(this)
    )
  }

  handleRecalculationEvent(event) {
    console.log('event received, detail: ', event.detail)
    this.recalculate()
  }
}
