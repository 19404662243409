document.addEventListener('DOMContentLoaded', function () {
    const enterpriseSelect = document.getElementById('enterprise_select');
    const destroyField = document.getElementById('site_enterprise_site_attributes__destroy');
    if (enterpriseSelect && destroyField) {
        enterpriseSelect.addEventListener('change', function () {
            if (this.value === "") {
                destroyField.value = '1';
            } else {
                destroyField.value = '0';
            }
        });
    }
});