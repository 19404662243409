import { ApplicationController } from "./application_controller.js"
import actionCable from 'actioncable'
import moment from 'moment'
export default class extends ApplicationController {
  static targets = [ 'health', 'lastSync', 'nextSync','runStatus' ]
  static values = {
    implementation: String
  }

  refreshData(){
    if (this.lastSync){
      this.lastSyncTargets.forEach((item, i) => {
        item.innerHTML = moment(this.lastSync).fromNow()
      });
    }
    this.nextSyncTargets.forEach((item, i) => {
      if (this.runStatus && this.enabled){
        item.innerHTML = this.runStatus
      } else if (this.enabled) {
        item.innerHTML = moment(this.nextSync).fromNow()
      } else {
        item.innerHTML = ""
      }
    })
  }

  connected(){
    console.log('ws connected!')
  }

  disconnected(){
    console.log('ws disconnected!')
  }

  received(data){
    console.log('ws data recd', data)
    this.lastSync = data.last_sync
    this.nextSync = data.next_sync
    this.runStatus = data.run
    this.status = data.status
    this.enabled = data.enabled
    this.healthTargets.forEach((item, i) => {
      if (data.status){
        item.innerHTML = data.status
      } else {
        item.innerHTML = ''
      }
    });
    this.nextSyncTargets.forEach((item, i) => {
      if (data.run){
        item.innerHTML = data.run
      }
    });
  }

  connect() {
    var controller = this;
    setInterval(this.refreshData.bind(this), 5000)
    console.log('hello feed', this.implementationValue)
    console.log('healthTargets',this.healthTargets)
    this.cable = actionCable.createConsumer(`wss://${window.location.hostname}/cable`)
    // window.moment = moment;
    this.cable.subscriptions.create({
      channel: "IntegrationStatusChannel",
      implementation: this.implementationValue,
    }, {
      connected: this.connected.bind(this),
      disconnected: this.disconnected.bind(this),
      received: this.received.bind(this),
    }) // sub.create({})
  } // connect()
}
