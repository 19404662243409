import { ApplicationController } from "./application_controller.js"
import actionCable from 'actioncable'
export default class extends ApplicationController {
  static values = {
    pdf: String
  }
  static targets = ['button','caption','waitingIndicator','downloadIndicator','errorIndicator', 'pleaseWait']
  hideIndicators(){
    this.downloadIndicatorTargets.forEach((ele) => this.hideElement(ele))
    this.errorIndicatorTargets.forEach((ele) => this.hideElement(ele))
    this.waitingIndicatorTargets.forEach((ele) => this.hideElement(ele))
  }

  setButtonClass(klass){
    this.removeClass(this.buttonTarget,'btn-primary')
    this.removeClass(this.buttonTarget,'btn-danger')
    this.removeClass(this.buttonTarget,'btn-default')
    this.addClass(this.buttonTarget,klass)
  }
  connect() {
    var controller = this;
    let pdf = this.pdfValue
    setInterval(()=>{
      if (!controller.hasCaptionTarget){ return ;}
      if (controller.captionTarget.innerHTML.match(/\.\.\./)) {
        controller.captionTarget.innerHTML = controller.captionTarget.innerHTML.replace("...",".")
      } else if (controller.captionTarget.innerHTML.match(/\.\./)){
        controller.captionTarget.innerHTML = controller.captionTarget.innerHTML.replace("..","...")
      } else if (controller.captionTarget.innerHTML.match(/\./)){
        controller.captionTarget.innerHTML = controller.captionTarget.innerHTML.replace(".","..")
      }
    },1000)
    this.cable = actionCable.createConsumer(`wss://${window.location.hostname}/cable`)
    // window.moment = moment;
    this.cable.subscriptions.create({
      channel: "PdfGenerationChannel",
      pdf: pdf
    }, {
      connected: function(){
        console.log('ws connected!')
      },
      disconnected: function(){
        console.log('ws disconnected!')
      },
      received: function(data){
        console.log('ws data recd', data)
        switch (data.status) {
          case 'initiated':
            controller.setButtonClass('btn-default')
            controller.hideIndicators()
            controller.waitingIndicatorTargets.forEach((ele) => controller.showElement(ele))
            controller.buttonTarget.disabled = true
            controller.captionTarget.innerHTML = 'Waiting for a Worker...'
            break;
          case 'working':
            controller.setButtonClass('btn-default')
            controller.hideIndicators()
            controller.buttonTarget.disabled = true
            controller.captionTarget.innerHTML = 'Working...'
            controller.waitingIndicatorTargets.forEach((ele) => controller.showElement(ele))
            break;
          case 'completed':
            controller.setButtonClass('btn-primary')
            controller.hideIndicators()
            controller.buttonTarget.disabled = false
            controller.captionTarget.innerHTML = 'Download PDF'
            controller.downloadIndicatorTargets.forEach((ele) => controller.showElement(ele))
            controller.pleaseWaitTargets.forEach((ele) => controller.hideElement(ele))
            break;
          case 'error':
            controller.setButtonClass('btn-danger')
            controller.hideIndicators()
            controller.buttonTarget.disabled = true
            controller.captionTarget.innerHTML = 'An Error Occurred'
            controller.errorIndicatorTargets.forEach((ele) => controller.showElement(ele))
            controller.pleaseWaitTargets.forEach((ele) => controller.hideElement(ele))
            break;
          case 'no-data':
            controller.setButtonClass('btn-danger')
            controller.hideIndicators()
            controller.buttonTarget.disabled = true
            controller.captionTarget.innerHTML = 'No Matching Data Found'
            controller.errorIndicatorTargets.forEach((ele) => controller.showElement(ele))
            controller.pleaseWaitTargets.forEach((ele) => controller.hideElement(ele))
            break;
          default:

        }
      },
    }) // sub.create({})
  } // connect()
}
