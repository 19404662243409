import { ApplicationController } from "./application_controller.js"
import TomSelect from 'tom-select'
export default class extends ApplicationController {
  static targets = ['select']
  connect() {
    const options = Array.from(this.select.getElementsByTagName('option'))
    this.allValues = options.map((element) => element.value)
    console.log('allValues',this.allValues)
    this.tomselect = new TomSelect(this.select, {maxOptions: null})
  }
  get select(){
    if (this.hasSelectTarget){
      return this.selectTarget
    } else {
      return this.element
    }
  }  
}
