import bootstrap3 from '../components/FormioBootstrap3Template'
import { Formio } from 'formiojs'

import { ApplicationController } from './application_controller.js'

export default class extends ApplicationController {
  static targets = ['form']
  static values = {
    template: Array,
    data: Object,
    metadata: Object,
  }
  connect() {
    const controller = this
    Formio.use(bootstrap3)
    Formio.Templates.framework = 'bootstrap3'
    console.log('template', this.templateValue)
    console.log('data', this.dataValue)
    console.log('metadata', this.metadataValue)
    if (!this.hasFormTarget) {
      console.log('formTarget not found')
      return
    }
    this.formChanged = false
    this.formTarget.dataset.changed = false
    Formio.createForm(this.formTarget, {
      components: this.templateValue,
    }, {
      readOnly: true
    }).then((form) => {
      form.submission = {
        data: this.dataValue,
        metadata: this.metadataValue,
      }
    })
  }
}
