import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController {
  static targets = [
    'latitude','longitude','location','site','zones','address','city','state',
    'zip'
 ]
  connect() {
    console.log('connected', this.element)
    this.google = window.google
    this.autocomplete = new this.google.maps.places.Autocomplete(
      this.locationTarget,
      {
        fields: [
          "address_components", "adr_address","formatted_address",
          "geometry", "utc_offset_minutes"
        ],
        types: ["address"]
      }
    )
    var controller = this
    this.autocomplete.addListener("place_changed", () => {
      var place = controller.autocomplete.getPlace();
      console.log(place);
      console.log(place.utc_offset_minutes)
      controller.latitudeTarget.value = place.geometry.location.lat();
      controller.longitudeTarget.value = place.geometry.location.lng();
      controller.addressTarget.value =
        controller.addrComponent(place, 'street_number','short_name')
        + ' '
        + controller.addrComponent(place, 'route','short_name')
      controller.cityTarget.value = controller.addrComponent(place, 'locality','short_name');
      controller.stateTarget.value = controller.addrComponent(place, "administrative_area_level_1",'short_name');
      controller.zipTarget.value = controller.addrComponent(place, "postal_code",'short_name');
    });
  }

  addrComponent(place, key, nametype){
    var value = '';
    var components = place.address_components;
    for (var i = 0; i < components.length; i++) {
      var component = components[i];
      if (component.types.indexOf(key) >= 0){
        value = component[nametype];
      }
    }
    return value
  }

}
