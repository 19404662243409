import { ApplicationController } from './application_controller.js'
import TomSelect from 'tom-select'
import { api } from '../components/AxiosHeaders.js'

/* 
   this controller is non-functional. 
   having problems dynamically showing zone-scoped selects for age group. 
*/
export default class extends ApplicationController {
  static targets = [
    'age',
    'awayTeam',
    'gameAttribute',
    'gameType',
    'gender',
    'homeTeam',
    'league',
    'payor',
    'subvenue',
    'team',
    'venue',
    'zone'
  ]
  static values = {
    addAgeGroup: Boolean,
    addGameType: Boolean,
    addGender: Boolean,
    addLeague: Boolean,
    addTeam: Boolean,
    addVenue: Boolean,
  }

  connect() {
    console.log('game_entry initialized', this.element)
    const renderItem = (
      data,
      escape
    ) => `<div style="display: flex; flex-direction: row; gap: 5px">
            <i class="fa-solid fa-caret-down"></i> 
            <span>${escape(data.text)}</span>
          </div>`
    const tsOptions = {
      plugins: {
        clear_button: {
          html: (
            data
          ) => `<div style="flex: 1; display: flex; flex-direction: row; justify-content: flex-end;">
            <div style="align-self: flex-end; padding-right: 5px" class="${data.className} fa-solid fa-xmark fa-color-danger"></div>
          </div>`
        }
      },
      render: {
        item: renderItem
      },
      maxOptions: null
    }
    const controller = this 
    controller.venueSubvenues = []
    console.log('ageTargeet', this.ageTarget)
    this.leagueSelect = new TomSelect(this.leagueTarget, {
      ...tsOptions,
      create: (this.addLeagueValue && this.addLeague.bind(this)),
    })
    this.ageSelect = new TomSelect(this.ageTarget, {
      ...tsOptions,
      create: (this.addAgeGroupValue && this.addAge.bind(this)),
    })
    this.genderSelect = new TomSelect(this.genderTarget, {
      ...tsOptions,
      create: (this.addGenderValue && this.addGender.bind(this)),
    })
    this.gameTypeSelect = new TomSelect(this.gameTypeTarget, {
      ...tsOptions,
      create: (this.addGameTypeValue && this.addGameType.bind(this)),
    })
    this.awayTeamSelect = new TomSelect(this.awayTeamTarget, {
      ...tsOptions,
      create: (this.addTeamValue && this.addTeam.bind(this)),
    })
    this.homeTeamSelect = new TomSelect(this.homeTeamTarget, {
      ...tsOptions,
      create: (this.addTeamValue && this.addTeam.bind(this)),
    })
    this.gameAttributeTargets.forEach(
      (target) => new TomSelect(target, tsOptions)
    )
    if (this.hasPayorTarget){
      this.payorSelect = new TomSelect(this.payorTarget, tsOptions)
    }
    this.venueSelect = new TomSelect(this.venueTarget, {
      ...tsOptions,
      create: (this.addVenueValue && this.addVenue.bind(this)),
    })
    this.zoneTargets.forEach((target) => new TomSelect(target, tsOptions))
    this.subvenueSelect = new TomSelect(this.subvenueTarget, {
      // items: [],
      maxItems: 1,
      onClear: () => {
        controller.subvenueSelect.clear()
        controller.subvenueSelect.clearOptions()
      },
      create: (input)=> {
        if (controller.venueSubvenues.length == 0){
          // when there are no defined subvenues for the venue, allow user to create one, and clear any other options
          controller.subvenueSelect.clearOptions()
          return {value: input, text: input}
        } else {
          return false
        }
      },
      render: {
        item: renderItem
      }
    })
    this.getSubvenues()
  }

  addVenue(input, callback){
    console.log(`creating new venue "${input}", zone ${this.zoneValue}...`)
    api
      .post('/venues.json', {
        venue: {
          name: input,
          zone_id: this.zoneValue
        }
      })
      .then((response) => {
        console.log('created!', response.data)
        const { id, name } = response.data
        var t = document.createElement('template');
        t.innerHTML = `<p class="help-block">Configure an Address for <a href="/venues/${id}/edit" target="blank">${name}</a></p>`;
        this.venueTarget.parentNode.appendChild(t.content);
        callback({
          id: id.toString(),
          value: id.toString(),
          text: name
        })
      })
      .catch((error) => {
        const { response, request } = error
        try {
          const message = error.response.data._embedded.errors.map((error) => error.message).join(', ')
          alert(`Could not create Venue: ${input} ${message}.`)
        } catch (e) {
          alert(`Could not create Venue ${input}.`)          
        }
        console.log('error creating new venue', error)
        console.log('response', response)
        console.log('request', request)
        callback()
      })
  }

  addTeam(input, callback){
    const controller = this 
    console.log(`creating new team "${input}", zone ${this.zoneValue}...`)
    api
      .post('/teams.json', {
        team: {
          name: input,
          zone_id: this.zoneValue
        }
      })
      .then((response) => {
        console.log('created!', response.data)
        const { id, name } = response.data
        const item = {
          id: id.toString(),
          value: id.toString(),
          text: name
        }
        controller.teamTargets.forEach((teamTarget) => {
          const ts = teamTarget.tomselect
          ts.addOption(item)
        })
        // this.homeTeamSelect.addOption(item)
        // this.awayTeamSelect.addOption(item)
        callback(item)
      })
      .catch((error) => {
        console.log("error!", error)
        const { response, request } = error
        try {
          console.log("server response", error.response.data)
          const message = error.response.data._embedded.errors.map((error) => error.message).join(', ')
          alert(`Could not create Team: ${input} ${message}.`)
        } catch (e) {
          alert(`Could not create Team ${input}.`)          
        }
        console.log('error creating new team', error)
        console.log('response', response)
        console.log('request', request)
        callback()
      })
  }

  addAge(input, callback) {
    return this.addItem(input, callback, '/age_groups.json', 'age_group')
  }

  addLeague(input, callback) {
    return this.addItem(input, callback, '/leagues.json', 'league')
  }

  addGender(input, callback) {
    return this.addItem(input, callback, '/genders.json', 'gender')
  }

  addGameType(input, callback) {
    return this.addItem(input, callback, '/game_types.json', 'game_type')
  }

  addItem(input, callback, endpoint, key){
    console.log(`creating new ${key} "${input}", zone ${this.zoneValue}...`)
    const data = {}
    data[key] = {
      name: input,
      zone_id: this.zoneValue,
    }
    return api
      .post(endpoint, data)
      .then((response) => {
        const { id, name } = response.data
        callback({
          id: id.toString(),
          value: id.toString(),
          text: name
        })
      })
      .catch((error) => {
        try {
          const message = error.response.data._embedded.errors.map((error) => error.message).join(', ')
          alert(`Could not create ${key}: ${input} ${message}.`)
        } catch (e) {
          alert(`Could not create ${key} ${input}.`)          
        }
        console.log(`could not add ${key}`, error)
        const { response, request } = error
        const { data } = response || {}
        console.log('response', response)
        console.log('request', request)
        callback()
      })
  }

  getSubvenues() {
    const venueId = this.venueTarget.value
    console.log(`venue changed to ${venueId}`)
    const controller = this
    if (venueId) {
      api.get(`/venues/${venueId}.json`).then((response) => {
        const venue = response.data
        const {subvenues} = venue
        controller.venueSubvenues = subvenues
        controller.subvenueSelect.clearOptions()
        if (subvenues.length > 0) {
          console.log('found subvenues',subvenues)
          if (!subvenues.find((subvenue) => subvenue === controller.subvenueTarget.value)) {
            console.log("clearing selection, did not find ", controller.subvenueTarget.value)
            controller.subvenueSelect.clear()
          }
          controller.subvenueSelect.clearOptions()
          controller.subvenueSelect.addOptions(subvenues.map((subvenue) => ({value:subvenue, text:subvenue})))  
        } else {
          // venue has no defined subvenues
        } 
      })
    } else {
      this.subvenueSelect.clear()
      this.subvenueSelect.clearOptions()
      //venue was cleared or is undefined
    }
  }



  get zoneValue() {
    return this.hasZoneTarget ? this.zoneTarget.value : null
  }

  reloadDependencies() {
    console.log('reloadDependencies!')
    // this.ageSelect.trigger('load')
    // this.ageSelect.refreshOptions()
  }
}
