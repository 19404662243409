import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = ['timesheet',"table","zoneSelect","assignmentSelect","hours"]
  connect(){
    console.log('connected multi-timesheet',this.element)
    this.zoneSelectTargets.forEach(zoneSelect => this.setActiveZones(zoneSelect))
  }

  setActiveZones(element){
    if (!this.hasZoneSelectTarget) {return;}
    let row = element.closest("tr")
    let assignmentSelect = this.assignmentSelectTargets.find((as) => row.contains(as))
    let zoneSelect = this.zoneSelectTargets.find((as) => row.contains(as))
    let assignmentOption = assignmentSelect.options[assignmentSelect.selectedIndex]
    console.log("selected option:",assignmentOption)
    let zoneIds = assignmentOption.dataset.zoneIds.split(",")
    console.log("zoneids",zoneIds)
    const zoneSelectOptions = Array.from(zoneSelect.options)
    zoneSelectOptions.forEach((option)=>{
      option.disabled = !(zoneIds.indexOf(option.value) >= 0);
    })
    // set value to the first non-disabled option
    for (var i = 0; i < zoneSelect.options.length; i++) {
      if (!zoneSelect.options[i].disabled){
        zoneSelect.value = zoneSelect.options[i].value
        i = zoneSelect.options.length
      }
    }
  }

  add(event){
    let newId = Math.floor(Math.random() * 10000000).toString()
    event.preventDefault()
    console.log("add!",event)
    console.log('this.timesheetTargets',this.timesheetTargets)
    let lastRow = this.timesheetTargets[this.timesheetTargets.length - 1]
    let lastRowAssignmentSelect = this.assignmentSelectTargets.find((element) => lastRow.contains(element))
    let currentId = lastRow.dataset.rowNumber
    console.log("row number is ",[currentId,newId])
    let newRow = lastRow.cloneNode(true);
    console.log('elements', newRow.querySelectorAll("[name]"))
    newRow.dataset.rowNumber = newId
    let nameMatcher = new RegExp(`^timesheet\\[${currentId}\\]`,'i')
    let idMatcher = new RegExp(`_${currentId}_`)
    newRow.querySelectorAll("[name]").forEach((element) => {
      console.log("element.name",element.name, nameMatcher)
      let found = element.name.match(nameMatcher)
      console.log('matches', found)
      if (found){
        element.name = element.name.replace(nameMatcher,`timesheet[${newId}]`)
      }
    })
    newRow.querySelectorAll("[id]").forEach((element) => {
      console.log("element.id",element.id, idMatcher)
      let found = element.id.match(idMatcher)
      console.log('matches', found)
      if (found){
        element.id = element.id.replace(idMatcher,`_${newId}_`)
      }
    })
    // unset the hours
    newRow.querySelectorAll("[data-multi-timesheet-target='hours']").forEach((hour) => {
      console.log("unsetting value for",hour)
      hour.value = ""
    })
    // set the value of the new row assignment select from the last row
    newRow.querySelectorAll("[data-multi-timesheet-target='assignmentSelect']").forEach((assignmentSelect) => {
      assignmentSelect.value = lastRowAssignmentSelect.value
    })
    this.tableTarget.appendChild(newRow);
  }

  remove(event){
    event.preventDefault()
    console.log("remove!",event)
    if (this.timesheetTargets.length <= 1) {
      return;
    }
    let button = event.target
    let row = button.closest("tr")
    row.remove()
  }

  changeAssignment(event){
    console.log('change assignment',event)
    this.setActiveZones(event.target)
    // let select = event.target
    // let row = select.closest("tr")
    // let zoneSelect = select.closest("tr")
  }
}
