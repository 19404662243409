import { ApplicationController } from './application_controller.js'
const COUNTRY_FROM_IP =
  document.querySelector("meta[name='country']").getAttribute('content') || 'US'

export default class extends ApplicationController {
  static targets = [
    'latitude',
    'longitude',
    'autofill',
    'city',
    'street',
    'street2',
    'state',
    'postal',
    'search',
    'place',
    'name'
  ]

  connect() {
    console.log('venue-input connected', window.google)
    console.log('latitudeTargets', this.latitudeTargets)
    if (!window.google) {
      return
    }
    this.google = window.google
    if (this.hasSearchTarget) {
      let options = {
        componentRestrictions: { country: COUNTRY_FROM_IP }
      }
      console.log('autocomplete with opts', options)
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchTarget,
        options
      )
      autocomplete.setFields([
        'address_component',
        'formatted_phone_number',
        'geometry',
        'place_id',
        'website',
        'name',
        'types',
        'formatted_address'
      ])
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', () => {
        console.log('place changed')
        const pick = autocomplete.getPlace()
        console.log('place: ', pick)
        this.setPlace(pick)
      })
    }
  }

  setPlace(place) {
    const {
      address_components,
      geometry,
      place_id,
      formatted_phone_number,
      name
    } = place
    this.street2Targets.forEach((target) => (target.value = ''))
    const street_number = address_components.find((comp) =>
      comp.types.includes('street_number')
    )
    const street = address_components.find((comp) =>
      comp.types.includes('route')
    )
    const locality = address_components.find((comp) =>
      comp.types.includes('locality')
    )

    const sublocality = address_components.find((comp) =>
      comp.types.includes('sublocality')
    )
    const state = address_components.find((comp) =>
      comp.types.includes('administrative_area_level_1')
    )
    const postal = address_components.find((comp) =>
      comp.types.includes('postal_code')
    )
    const latitude = geometry.location.lat()
    const longitude = geometry.location.lng()
    this.streetTargets.forEach((target) => {
      if (street_number) {
        target.value = `${street_number.short_name} ${street.short_name}`
      }
    })
    this.cityTargets.forEach((target) =>
      this.setValueFromShortName(target, locality || sublocality)
    )
    this.stateTargets.forEach((target) =>
      this.setValueFromShortName(target, state)
    )
    this.postalTargets.forEach((target) =>
      this.setValueFromShortName(target, postal)
    )
    this.latitudeTargets.forEach((target) => (target.value = latitude))
    this.longitudeTargets.forEach((target) => (target.value = longitude))
    this.nameTargets.forEach((target) => (target.value = name))
    this.placeTargets.forEach((target) => (target.value = place_id))
  }
  setValueFromShortName(target, value) {
    try {
      target.value = value.short_name
    } catch (error) {
      console.log('error', error)
    }
  }
}
