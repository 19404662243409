import Dinero from 'dinero.js'

export const inputToInteger = (value) => {
  if (value) {
    return Math.floor(parseFloat(value || 0.0) * 100.0)
  } else {
    return 0
  }
}

export const intToCurrency = (amount) => {
  return Dinero({ amount }).toFormat('0,0.00')
}

export const sum = (result, item) => {
  return result + Number(item || 0.0)
}

