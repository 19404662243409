import { ApplicationController } from './application_controller.js'
import { api } from '../components/AxiosHeaders.js'
export default class extends ApplicationController {
  static targets = ['form']
  static values = { source: String }
  connect() {
    console.log(
      'form data on connect',
      Array.from(new FormData(this.formTarget))
    )
  }
  saveFilter() {
    const formData = new FormData(this.formTarget)
    console.log('formData', formData)
    const filterName = prompt(
      'Please provide a name for this filter.',
      'Unnamed Filter, ' + new Date().toLocaleString()
    )
    if (!filterName) {
      return
    }
    const toSubmit = new FormData()
    Array.from(formData).forEach((pair) => {
      const key = pair[0]
      const value = pair[1]
      const newKey = key
        .toString()
        .replace(/^(search|session_search)/, 'filter[criteria]')
      console.log('adding', newKey, value)
      toSubmit.append(newKey, value)
    })
    toSubmit.append('filter[name]', filterName)
    api
      .post('/games/filters/save.json', toSubmit, {
        headers: { 'x-criteria-source': this.sourceValue }
      })
      .then((response) => {
        alert('Filter saved successfully.')
        console.log('ok!', response)
      })
      .catch((error) => {
        console.log('nope!', error)
      })
  }
}
