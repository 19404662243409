import { ApplicationController } from './application_controller.js'
export default class extends ApplicationController {
  static targets = [
    'password',
    'confirmation',
    'feedback',
    'userInput',
    'warning',
    'suggestions',
    'progressbar',
    'staticAdvice',
  ]
  connect() {
    console.log('connected to zxcvbn')
    var controller = this
    import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then((module) => {
      controller.zxcvbn = module.default
      console.log('zxcvbn lazy loaded')
    })
  }

  check() {
    var password = this.passwordTarget.value || ''
    var inputs = this.userInputTargets.map((target) => target.value)
    inputs = inputs.concat([
      'assignr',
      'referee',
      'assignor',
      'umpire',
      'gridiron',
      'linesman',
      'football',
      'soccer',
      'cricket',
      'hockey',
      'volleyball',
      'basketball',
      'baseball',
      'rugby'
    ])
    console.log('inputs', inputs)
    if (!this.zxcvbn) {
      this.staticAdviceTargets.forEach((target) => this.showElement(target))
      return
    }
    var pwd = this.zxcvbn(password, inputs)
    console.log(pwd)
    var letters = !!password.match(/[a-zA-Z]/)
    var numbers = !!password.match(/[0-9]/)
    var symbols = !!password.match(/[^a-zA-Z0-9]/)
    var length = password.length >= 12
    var score = pwd.score + 1
    var feedback = []
    this.suggestionsTarget.innerHTML = ''
    var valid = true
    if (!letters) {
      this.appendSuggestion('Letters needed')
      valid = false
    }
    if (!numbers) {
      this.appendSuggestion('Numbers needed')
      valid = false
    }
    if (!symbols) {
      this.appendSuggestion('Symbols needed')
      valid = false
    }
    if (!length) {
      this.appendSuggestion('Too short (12 characters)')
      valid = false
    }
    if (pwd.feedback.warning) {
      this.appendSuggestion(pwd.feedback.warning)
    }
    if (valid) {
      score = 5
    }
    if (!valid && score > 4) {
      score = 4
    }
    if (this.confirmationTarget.value != password) {
      this.appendSuggestion("Password and confirmation don't match.")
      valid = false
      score = 4
    }
    this.progressbarTarget.setAttribute('style', 'width: ' + (score * 20 + '%'))
    this.removeClass(this.progressbarTarget, 'progress-bar-danger')
    this.removeClass(this.progressbarTarget, 'progress-bar-warning')
    this.removeClass(this.progressbarTarget, 'progress-bar-success')
    switch (score) {
      case 0:
      case 1:
      case 2:
        this.addClass(this.progressbarTarget, 'progress-bar-danger')
        break
      case 3:
      case 4:
        this.addClass(this.progressbarTarget, 'progress-bar-warning')
        break
      case 5:
        this.addClass(this.progressbarTarget, 'progress-bar-success')
        break
    }
  }

  appendSuggestion(value) {
    this.staticAdviceTargets.forEach((target) => this.hideElement(target))
    var ul = this.suggestionsTarget
    var li = document.createElement('li')
    li.appendChild(document.createTextNode(value))
    ul.appendChild(li)
  }
}
