import { ApplicationController } from "./application_controller.js"
import actionCable from 'actioncable'
export default class extends ApplicationController {
  static targets = [ 'outstanding','blankslate','count','amount','working', 'completed' ]
  connect() {
    this.payorState = {}
    var controller = this;
    console.log('hello payor balance')
    this.cable = actionCable.createConsumer(`wss://${window.location.hostname}/cable`)
    // window.moment = moment;
    this.cable.subscriptions.create({
      channel: "PayorBalanceChannel",
      payor: this.element.dataset.payor,
      site: this.element.dataset.site,
      user: this.element.dataset.user,
      zone: this.element.dataset.zone,
    }, {
      connected: function(){
        console.log('ws connected!')
      },
      disconnected: function(){
        console.log('ws disconnected!')
        controller.workingTargets.forEach((element)=>{
          controller.addClass(element, 'hidden')
        })
        controller.completedTargets.forEach((element)=>{
          controller.addClass(element, 'hidden')
        })
      },
      received: function(data){
        console.log('ws data recd', data)
        // console.log("this", this)
        // console.log("this.caller", this.caller)
        var unpaid = data.unpaid_count > 0;
        controller.countTargets.forEach((target)=>{
          target.innerHTML = data.unpaid_count
        })
        controller.amountTargets.forEach((target)=>{
          target.innerHTML = data.unpaid_amount
        })
        if (controller.hasOutstandingTarget){
          controller.outstandingTargets.forEach((target)=>{
            if (unpaid){
              controller.removeClass(target, 'hidden')
            } else {
              controller.addClass(target, 'hidden')
            }
          })
        }
        if (controller.hasBlankslateTarget){
          controller.blankslateTargets.forEach((target)=>{
            if (unpaid){
              controller.addClass(target, 'hidden')
            } else {
              controller.removeClass(target, 'hidden')
            }
          })
        }
        if (controller.hasWorkingTarget){
          console.log('working target...')
          if (data.in_process){
            console.log('in process')
            controller.workingTargets.forEach((element)=>{
              controller.removeClass(element,'hidden')
            })
          } else {
            console.log('not in process')
            controller.workingTargets.forEach((element)=>{
              controller.addClass(element, 'hidden')
            })
          }
          if (controller.payorState.in_process && !data.in_process){
            console.log('finished...')
            // finished
            // hide the 'working' indicator
            // we do this here as well in case messages are
            // received out of order.
            controller.workingTargets.forEach((element)=>{
              window.setTimeout(function(){
                controller.addClass(element, 'hidden')
              }, 5000);
            })
            controller.completedTargets.forEach((element)=>{
              controller.removeClass(element, 'hidden')
              window.setTimeout(function(){
                controller.addClass(element, 'hidden');
              }, 5000);
            })
          }
        }
        controller.payorState = data
      },
    }) // sub.create({})
  } // connect()
}
