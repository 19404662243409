import { ApplicationController } from './application_controller.js'
import { api } from '../components/AxiosHeaders.js'
import Dinero from 'dinero.js'
import moment from 'moment'

export default class extends ApplicationController {
  static targets = [
    'select',
    'quantity',
    'when',
    'dueDate',
    'price',
    'total',
    'submit',
    'changes',
    'downgrade',
    'totalDue',
  ]
  static values = {
    previewUrl: String
  }
  connect() {
    this.refresh()
  }

  refresh() {
    this.submitTargets.forEach((element) => {
      element.disabled = true
    })
    api
      .get(this.previewUrlValue, {
        params: {
          quantity: this.quantityTarget.value,
          price: this.priceTarget.value,
          // when: this.whenTarget.value
        }
      })
      .then((response) => {
        const { today, total, invoice, proration_date, same, downgrade, items } =
          response.data
        if (downgrade){
          this.downgradeTargets.forEach((element) => this.showElement(element))
          this.totalDueTargets.forEach((element) => this.hideElement(element))
        } else {
          this.downgradeTargets.forEach((element) => this.hideElement(element))
          this.totalDueTargets.forEach((element) => this.showElement(element))
        }
        if (downgrade) {
          this.totalTarget.innerHTML = '$0.00 (downgrade)'
        } else if (same && today) {
          this.totalTarget.innerHTML = '$0.00'
        } else {
          this.totalTarget.innerHTML = Dinero({
            amount: total,
            currency: invoice.currency
          }).toFormat('$0,0.00')
        }
        if (today) {
          this.dueDateTarget.innerHTML = 'Today'
        } else {
          this.dueDateTarget.innerHTML = moment
            .unix(proration_date)
            .format('MMMM Do, YYYY')
        }
        Array.from(this.changesTarget.children).forEach((child) => child.remove())
        items.forEach((item) => {
          const tr = document.createElement('tr')
          const td1 = document.createElement('td')
          td1.innerHTML = item.description
          tr.appendChild(td1)
          const td2 = document.createElement('td')
          td2.innerHTML = Dinero({amount: item.amount, currency: invoice.currency}).toFormat('$0,0.00')
          td2.style.textAlign = 'right'
          tr.appendChild(td2)
          this.changesTarget.appendChild(tr)
        })
        const totalTr = document.createElement('tr')
        const totalTd1 = document.createElement('td')
        const totalTd2 = document.createElement('td')
        const grandTotal = items.map((item) => item.amount).reduce((a, b) => a + b, 0)
        totalTd2.innerHTML = Dinero({amount: grandTotal, currency: invoice.currency}).toFormat('$0,0.00')
        if (grandTotal < 0.0){
          totalTd1.innerHTML = 'Difference'
        } else {
          totalTd1.innerHTML = 'Total'
        }        
        totalTd2.style.textAlign = 'right'
        totalTd2.style.fontWeight = 'bold'
        totalTd1.style.fontWeight = 'bold'
        totalTr.appendChild(totalTd1)
        totalTr.appendChild(totalTd2) 
        this.changesTarget.appendChild(totalTr)
        
        this.submitTargets.forEach((element) => {
          element.disabled = same
        })
        console.log('ok!', response.data)
      })
      .catch((error) => {
        this.submitTargets.forEach((element) => {
          element.disabled = true
        })
        this.downgradeTargets.forEach((element) => this.hideElement(element))
        this.totalDueTargets.forEach((element) => this.hideElement(element))
        Array.from(this.changesTarget.children).forEach((child) => child.remove())
        console.log(error)
      })
  }
}
