/*
    I18n.available_locales.collect{|locale| locale.to_s.split("-")[0]}.uniq.collect do |locale|
        [
        locale,
            {
            months: I18n.t("date.month_names", locale: locale ).compact, 
            weekdays: I18n.t("date.day_names", locale: locale ),
            weekdaysShort: I18n.t("date.abbr_day_names", locale: locale ).compact,
            previousMonth: I18n.t("javascript.pikaday.previous_month", locale: locale ),
            nextMonth: I18n.t("javascript.pikaday.next_month", locale: locale ),
            }
        ]
    end.to_h.to_json
*/
const translated = {
    "en":{
        "months":["January","February","March","April","May","June","July","August","September","October","November","December"],
        "weekdays":["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
        "weekdaysShort":["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
        "previousMonth":"Previous Month","nextMonth":"Next Month"
    },
    "es":{
        "months":["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        "weekdays":["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
        "weekdaysShort":["Dom","Lun","Mar","Mié","Jue","Vie","Sáb"],
        "previousMonth":"Mes anterior",
        "nextMonth":"Próximo mes"
    }
}
const languageData = () => {
    const languageTag = document.querySelector('meta[name=language]')
    const language = languageTag ? languageTag.content : 'en' 
    return translated[language] || translated.en
};
const translations = {
    allData: translated,
    localData: languageData,
}
export default translations;