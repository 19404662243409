import { ApplicationController } from './application_controller.js'
import axios from 'axios'
import setAxiosHeaders from '../components/AxiosHeaders'
import TomSelect from 'tom-select'
const COUNTRY_FROM_IP =
  document.querySelector("meta[name='country']").getAttribute('content') || 'US'

export default class extends ApplicationController {
  static values = {
    markerLatitude: Number,
    markerLongitude: Number,
    mapCenterLatitude: Number,
    mapCenterLongitude: Number,
    name: String,
    venueId: Number
  }
  static targets = [
    'map',
    'latitude',
    'longitude',
    'autofill',
    'city',
    'street',
    'state',
    'postal',
    'search',
    'place',
    'name',
    'longName',
    'subvenues',
  ]

  enableEntry(event) {
    event.preventDefault()
    this.autofillTargets.forEach((target) => (target.disabled = false))
  }

  get formPopulated() {
    return (
      !!(this.venueIdValue > 0) ||
      !!(this.hasNameTarget && this.nameTarget.value)
    )
  }

  connect() {
    setAxiosHeaders()
    console.log('venue-input connected', window.google)
    console.log('latitudeTargets', this.latitudeTargets)
    if (!this.formPopulated) {
      this.autofillTargets.forEach((target) => (target.disabled = true))
    }
    if (!window.google) {
      return
    }
    this.google = window.google
    if (this.hasSearchTarget) {
      let options = {
        componentRestrictions: { country: COUNTRY_FROM_IP }
      }
      if (Math.abs(Math.floor(this.mapCenterLatitudeValue)) > 0) {
        options.bounds = {
          north: this.mapCenterLatitudeValue + 0.5,
          south: this.mapCenterLatitudeValue - 0.5,
          east: this.mapCenterLongitudeValue + 0.5,
          west: this.mapCenterLongitudeValue - 0.5
        }
      }
      console.log('autocomplete with opts', options)
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchTarget,
        options
      )
      autocomplete.setFields([
        'address_component',
        'formatted_phone_number',
        'geometry',
        'place_id',
        'website',
        'name',
        'types',
        'formatted_address'
      ])
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', () => {
        console.log('place changed')
        const pick = autocomplete.getPlace()
        console.log('place: ', pick)
        this.setPlace(pick)
      })
    }
    this.subvenuesTargets.forEach((target) => {
      new TomSelect(target,{
        persist: false,
        createOnBlur: true,
        create: true
      });
    })
    if (this.hasMapTarget && this.mapCenter) {
      this.map = new this.google.maps.Map(this.mapTarget, {
        zoom: 13,
        center: this.mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      })
      if (this.markerLatitudeValue) {
        this.marker = new this.google.maps.Marker({
          position: this.mapCenter,
          map: this.map,
          title: this.nameValue,
          draggable: true
        })
        this.google.maps.event.addListener(this.marker, 'dragend', () =>
          this.markerMoved(true)
        )
      }
      axios
        .get('/venues.json')
        .then((response) => {
          const filtered = response.data.filter(
            (venue) =>
              venue.latitude && venue.longitude && venue.id != this.venueIdValue
          )
          filtered.forEach((venue, i) => {
            const location = new this.google.maps.LatLng(
              venue.latitude,
              venue.longitude
            )
            const marker = new this.google.maps.Marker({
              position: location,
              map: this.map,
              title: venue.name
            })
            marker.setIcon('https://maps.google.com/mapfiles/marker_grey.png')
          })
        })
        .catch((e) => console.log('venue fetch error', e))
    }
  }

  get mapCenter() {
    if (this.markerLatitudeValue) {
      return new this.google.maps.LatLng(
        this.markerLatitudeValue,
        this.markerLongitudeValue
      )
    } else if (this.mapCenterLatitudeValue) {
      return new this.google.maps.LatLng(
        this.mapCenterLatitudeValue,
        this.mapCenterLongitudeValue
      )
    }
  }

  markerMoved(dragged) {
    console.log('markerMoved!', this.latitudeTargets, this.longitudeTargets)
    this.latitudeTargets.forEach(
      (item, i) => (item.value = this.marker.getPosition().lat())
    )
    this.longitudeTargets.forEach(
      (item, i) => (item.value = this.marker.getPosition().lng())
    )
    if (dragged) {
      this.pinDropped = true
    }
  }

  setPlace(place) {
    this.autofillTargets.forEach((target) => (target.disabled = false))
    const {
      address_components,
      geometry,
      place_id,
      formatted_phone_number,
      name
    } = place
    const street_number = address_components.find((comp) =>
      comp.types.includes('street_number')
    )
    const street = address_components.find((comp) =>
      comp.types.includes('route')
    )
    const locality = address_components.find((comp) =>
      comp.types.includes('locality')
    )

    const sublocality = address_components.find((comp) =>
      comp.types.includes('sublocality')
    )
    const state = address_components.find((comp) =>
      comp.types.includes('administrative_area_level_1')
    )
    const postal = address_components.find((comp) =>
      comp.types.includes('postal_code')
    )
    const latitude = geometry.location.lat()
    const longitude = geometry.location.lng()
    this.streetTargets.forEach((target) => {
      if (street_number) {
        target.value = `${street_number.short_name} ${street.short_name}`
      }
    })
    this.cityTargets.forEach((target) =>
      this.setValueFromShortName(target, (locality || sublocality))
    )
    this.stateTargets.forEach((target) =>
      this.setValueFromShortName(target, state)
    )
    this.postalTargets.forEach((target) =>
      this.setValueFromShortName(target, postal)
    )
    this.latitudeTargets.forEach((target) => (target.value = latitude))
    this.longitudeTargets.forEach((target) => (target.value = longitude))
    this.nameTargets.forEach((target) => (target.value = name))
    this.longNameTargets.forEach((target) => target.value = [sublocality?.short_name, locality?.short_name, state?.short_name].filter((a) => a).join(", "))
    this.placeTargets.forEach((target) => (target.value = place_id))
    const newLocation = new this.google.maps.LatLng(latitude, longitude)

    if (this.marker) {
      console.log('marker found')
      this.marker.setPosition(newLocation)
    } else {
      console.log('marker created')
      this.marker = new this.google.maps.Marker({
        position: newLocation,
        map: this.map,
        title: this.name,
        draggable: true
      })
      this.google.maps.event.addListener(this.marker, 'dragend', () =>
        this.markerMoved(true)
      )
    }
    this.map.setCenter(newLocation)
  }

  setValueFromShortName(target, value) {
    try {
      target.value = value.short_name
    } catch (error) {
      console.log('error', error)
    }
  }
}
