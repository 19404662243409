import { ApplicationController } from "./application_controller.js"
import setAxiosHeaders from '../components/AxiosHeaders'
import axios from 'axios'

export default class extends ApplicationController {
  static targets = ['job','rate','account']
  connect(){
    console.log('connected new-job',this.element)
    setAxiosHeaders()
    this.baseJobUrl = this.element.dataset.baseJobUrl
  }
  populate(){
    console.log('call populate')
    let job = this.jobTarget.value
    console.log('job',job)
    if (!job){ return }
    let url = `${this.baseJobUrl}/${job}.json`
    console.log("populate",url)
    let controller = this
    axios.get(url)
      .then( response => {
        console.log(response)
        this.rateTarget.value = response.data.default_rate
        this.accountTarget.value = response.data.ledger_account_id
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
  }
}
