import { ApplicationController } from "./application_controller.js"
import axios from 'axios'
import setAxiosHeaders from '../components/AxiosHeaders'
export default class extends ApplicationController {
  static values = { url: String, auth: String }
  static targets = [ 'account','routing','confirmation','accountType','form','name' ]
  connect() {
    console.log({url: this.urlValue, auth: this.authValue})
    setAxiosHeaders();
  }
  submit(event){
    if (this.nameTarget.value.length == 0){
      alert("Please provide a name for your bank account.");
      return;
    }
    if (this.routingTarget.value.length == 0){
      alert("Please provide a routing number.");
      return;
    }
    if (this.accountTarget.value.length == 0){
      alert("Please provide an account number.");
      return;
    }
    if (this.confirmationTarget.value.length == 0){
      alert("Please re-type the account number in the Confirm Account Number box.");
      return;
    }
    if (this.accountTypeTarget.value.length == 0){
      alert("Please choose an account type.");
      return;
    }
    if (this.confirmationTarget.value != this.accountTarget.value ){
      alert("The account number you provided doesn't match what you typed in the confirmation box.");
      return;
    }
    this.formTarget.submit();

  }
}
